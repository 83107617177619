import { css } from '@emotion/css';

import { appVariables } from '../../../config/variables';
  
export const styles = {
  pageHeader: css({
    width: '90%',
    marginTop: 0,
    marginBottom: 40
  }),
  exitButton: css({
    position: 'absolute',
    top: 30,
    right: 30,
  }),
  inputs: css({
    display: 'flex',
    flexDirection: 'column'
  }),
  input: css({
    backgroundColor: '#e6e3da',
    padding: 20,
    marginBottom: 20,
    fontSize: 15
  }),
  textarea: css({
    backgroundColor: '#e6e3da',
    minHeight: 100,
    padding: 20,
    marginBottom: 20,
    fontSize: 15,
  }),
  inputError: css({
    border: `4px solid ${appVariables.colors.red}`,
    backgroundColor: appVariables.colors.lightRed
  }),
  inputErrorText: css({
    fontFamily: appVariables.font.family,
    fontSize: 21,
    color: appVariables.colors.red
  }),
  errorsText: css({
    marginBottom: 20
  }),
  formButton: css({
    backgroundColor: appVariables.colors.purple,
    padding: 20
  }),
  formButtonText: css({
    color: 'white',
    fontSize: 15
  })
};
