import { makeObservable, observable, action } from 'mobx';

export default class UIStore {
  header = {
    isOverlayed: true,
    isHidden: false,
  }
  drawer = {
    isOpen: false
  }
  modals = {
   current: '',
  }
  currentPage = '';

  constructor() {
    makeObservable(this, {
      header: observable,
      drawer: observable,
      modals: observable,
      currentPage: observable,
      updateHeader: action,
      updateDrawer: action,
      updateModals: action,
      updateCurrentPage: action,
    });
  }

  updateHeader(updates: any) {
    this.header = {
      ...this.header,
      ...updates
    }
  }

  updateDrawer(updates: any) {
    this.drawer = {
      ...this.drawer,
      ...updates
    }
  }

  updateModals(updates: any) {
    this.modals = {
      ...this.modals,
      ...updates
    }
  }

  updateCurrentPage(page: string) {
    this.currentPage = page;
  }
}
