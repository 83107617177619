import { Page } from '../../models';
import { ActionBarType } from '../../shared/actionBar/enums/actionBarType';

export const data: { pages: { [key: string]: Page } } = {
  pages: {
    literature: {
      title: 'Literature',
      route: 'literature',
      emailable: true,
      icon: {
        height: 144,
        width: 116
      },
      children: {
        'drivon-technology-fuel-efficient-powertrain-solutions': {
          resourceType: ActionBarType.PDF,
          id: 'drivon-technology-fuel-efficient-powertrain-solutions',
          title: 'Fuel Efficient Powertrain Solution',
          link: 'https://oil-additives.evonik.com/product/oil-additives/Downloads/2021%20drivon-brochure-rebrand-v6-en-interactive.pdf',
        },
        'oil-additives-product-application-guide': {
          resourceType: ActionBarType.PDF,
          id: 'oil-additives-product-application-guide',
          title: 'Oil Additives Product Application Guide',
          link: 'https://oil-additives.evonik.com/product/oil-additives/downloads/oil-additives-product-application-guide-en.pdf',
        },
        'viscobase-synthetic-base-fluids': {
          resourceType: ActionBarType.PDF,
          id: 'viscobase-synthetic-base-fluids',
          title: 'VISCOBASE® Synthetic Base Fluids',
          link: 'https://oil-additives.evonik.com/product/oil-additives/downloads/eoa-viscobase-synthethic-base-fluids-brochure-rebrand-web.pdf',
        },
        'viscoplex-pour-point-depressant-technologies': {
          resourceType: ActionBarType.PDF,
          id: 'viscoplex-pour-point-depressant-technologies',
          title: 'VISCOPLEX® Pour Point Depressant Technologies',
          link: 'https://oil-additives.evonik.com/product/oil-additives/downloads/viscoplex-ppd-brochure-cover-update-v1-en-interactive.pdf',
        },
        'copi-technology-crude-oil-paraffin-inhibitors': {
          resourceType: ActionBarType.PDF,
          id: 'copi-technology-crude-oil-paraffin-inhibitors',
          title: 'COPI™ Technology Crude Oil Paraffin Inhibitors',
          link: 'https://oil-additives.evonik.com/product/oil-additives/Downloads/2021_Evonik%20COPI%20Brochure_FINAL.pdf',
        },
        'dynavis-technology-resource-efficient-hydraulic-additives': {
          resourceType: ActionBarType.PDF,
          id: 'dynavis-technology-resource-efficient-hydraulic-additives',
          title: 'DYNAVIS® Technology Resource Efficient Hydraulic Additives',
          link: 'https://oil-additives.evonik.com/product/oil-additives/downloads/industrial-hydraulic-fluid-brochure-interactive-v9.pdf',
        },
        'evoniks-viscoplex-additives-and-viscobase-base-oils': {
          resourceType: ActionBarType.PDF,
          id: 'evoniks-viscoplex-additives-and-viscobase-base-oils',
          title: 'Evoniks VISCOPLEX® Additives and VISCOBASE® Base Oils',
          link: 'https://oil-additives.evonik.com/product/oil-additives/downloads/automotive-brochure-v9-interactive.pdf',
        },
        'hydraulic-fluids-a-treatise-on-formula-for-performance-and-efficiency': {
          resourceType: ActionBarType.PDF,
          id: 'hydraulic-fluids-a-treatise-on-formula-for-performance-and-efficiency',
          title: 'Hydraulic Fluids A Treatise on Formula For Performance and Efficiency',
          link: 'https://oil-additives.evonik.com/product/oil-additives/downloads/hf-treatise-brochure-en-v10-interactive.pdf',
        },
        'nuflux-technology': {
          resourceType: ActionBarType.PDF,
          id: 'nuflux-technology',
          title: 'NUFLUX™ Technology',
          link: 'https://oil-additives.evonik.com/product/oil-additives/Downloads/2021-eoa-nuflux-industrial-gear-brochure-cover-update-web.pdf',
        },
        'viscoplex-additives-for-mobile-hydraulic-equipment': {
          resourceType: ActionBarType.PDF,
          id: 'viscoplex-additives-for-mobile-hydraulic-equipment',
          title: 'VISCOPLEX® Additives for Mobile Hydraulic Equipment',
          link: 'https://oil-additives.evonik.com/product/oil-additives/Downloads/VISCOPLEX%C2%AE%20additives%20for%20mobile%20hydraulic%20equipment%20(2021_02).pdf',
        },
        'viscoplex-additives-for-hydraulic-fluids-used-in-manufacturing-equipment': {
          resourceType: ActionBarType.PDF,
          id: 'viscoplex-additives-for-hydraulic-fluids-used-in-manufacturing-equipment',
          title: 'VISCOPLEX® Additives for Hydraulic Fluids Used in Manufacturing Equipment',
          link: ' https://oil-additives.evonik.com/product/oil-additives/Downloads/VISCOPLEX%C2%AE%20additives%20for%20hydraulic%20fluids%20used%20in%20manufacturing%20equipment%20(2021_02).pdf',
        },
        'viscoplex-cold-flow-improvers': {
          resourceType: ActionBarType.PDF,
          id: 'viscoplex-cold-flow-improvers',
          title: 'VISCOPLEX® Cold Flow Improvers(CFIs)',
          link: 'https://oil-additives.evonik.com/product/oil-additives/downloads/cfi-brochure-rebrand-en-v10-interactive.pdf',
        },
        'viscoplex-vii-shock-and-steering': {
          resourceType: ActionBarType.PDF,
          id: 'viscoplex-vii-shock-and-steering',
          title: 'VISCOPLEX® VII Shock and Steering',
          link: 'https://oil-additives.evonik.com/product/oil-additives/downloads/viscobase-nuflux-brochure-v9-en-interactive.pdf',
        },
        'viscoplex-additives-for-hydraulic-and-utto-fluids-in-agriculture-and-forestry': {
          resourceType: ActionBarType.PDF,
          id: 'viscoplex-additives-for-hydraulic-and-utto-fluids-in-agriculture-and-forestry',
          title: 'VISCOPLEX® Additives for Hydraulic and UTTO Fluids in Agriculture and Forestry',
          link: 'https://oil-additives.evonik.com/product/oil-additives/Downloads/Brochure%20Agriculture%20and%20Forestry%20EOA%20(2021_02).pdf',
        }
      }
    },
    calculators: {
      title: 'Calculators',
      route: 'calculators',
      icon: {
        height: 144,
        width: 104
      },
      children: {
        'blending-efficiency': {
          id: 'blending-efficiency',
          title: 'Blending Efficiency',
          description: 'Calculation of the % by weight of an Evonik Oil Additives VII needed to reach a desired viscosity at 100°C.',
        },
        'base-oil-mixtures': {
          id: 'base-oil-mixtures',
          title: 'Base Oil Mixtures',
          table1Title: '(KV1, KV2, %, T) > Final KV',
          table2Title: '(KV1, KV2, Final KV, T) > % Mixing',
        },
        'viscosity-index': {
          id: 'viscosity-index',
          title: 'Viscosity Index',
          table1Title: 'Viscosity Index (VI) Calculator',
          table2Title: 'Kinematic Viscosity @ 100°C Calculator',
          table3Title: 'Kinematic Viscosity @ 40°C Calculator'
        }
      }
    },
    // presentations: {
    //   title: 'Presentations',
    //   route: 'presentations',
    //   emailable: false,
    //   icon: {
    //     height: 120,
    //     width: 161
    //   },
    //   children: {
    //     'ppd-considerations-for-sae-0w-16-formulations': {
    //       resourceType: ActionBarType.PDF,
    //       id: 'ppd-considerations-for-sae-0w-16-formulations',
    //       title: 'PPD Considerations for SAE 0W-16 Formulations',
    //     },
    //     'efficiency-gains-in-construction-manufacturing-and-mining-have-become-reality-with-dynavis-technology': {
    //       resourceType: ActionBarType.PDF,
    //       id: 'efficiency-gains-in-construction-manufacturing-and-mining-have-become-reality-with-dynavis-technology',
    //       title: 'Efficiency gains in construction, manufacturing, and mining have become reality with DYNAVIS® technology',
    //     },
    //     'demonstrating-system-efficiency-of-construction-equipment': {
    //       resourceType: ActionBarType.PDF,
    //       id: 'demonstrating-system-efficiency-of-construction-equipment',
    //       title: 'Demonstrating System Efficiency of Construction Equipment',
    //     },
    //     'the-benefits-of-novel-functionalized-viscosity-index-improvers-in-wet-clutch-systems': {
    //       resourceType: ActionBarType.PDF,
    //       id: 'the-benefits-of-novel-functionalized-viscosity-index-improvers-in-wet-clutch-systems',
    //       title: 'The Benefits of Novel Functionalized Viscosity Index Improvers in Wet Clutch Systems',
    //     },
    //     'robo-as-a-formulation-tool-for-engine-oil-development': {
    //       resourceType: ActionBarType.PDF,
    //       id: 'robo-as-a-formulation-tool-for-engine-oil-development',
    //       title: 'ROBO as a Formulation Tool for Engine Oil Development',
    //     },
    //     'impact-of-vi-improver-on-the-formation-of-piston-deposits-in-fuel-efficient-engine-oils': {
    //       resourceType: ActionBarType.PDF,
    //       id: 'impact-of-vi-improver-on-the-formation-of-piston-deposits-in-fuel-efficient-engine-oils',
    //       title: 'Impact of VI Improver on the Formation of Piston Deposits in Fuel Efficient Engine Oils',
    //     }
    //   }
    // },
    website: {
      title: 'Website',
      externalRoute: 'https://oil-additives.evonik.com/en',
      requiresNetwork: true,
      icon: {
        height: 114,
        width: 163
      }
    },
    videos: {
      title: 'Videos',
      route: 'videos',
      emailable: true,
      icon: {
        height: 95,
        width: 153
      },
      children: {
        'drivon-the-ultimate-test': {
          resourceType: ActionBarType.VIDEO,
          id: 'drivon-the-ultimate-test',
          title: 'DRIVON™ Technology - The Ultimate Test',
          link: 'https://www.youtube.com/embed/zhsseLRXx4c',
        },
        'dynavis-performance-demonstration-siberia-russia': {
          resourceType: ActionBarType.VIDEO,
          id: 'dynavis-performance-demonstration-siberia-russia',
          title: 'DYNAVIS® Performance Demonstration in Siberia, Russia',
          link: 'https://www.youtube.com/embed/U7FNwWXM0ZE',
        },
        'image-of-innovation': {
          resourceType: ActionBarType.VIDEO,
          id: 'image-of-innovation',
          title: 'Image of Innovation',
          link: 'https://www.youtube.com/embed/tl_CYBruY3c',
        },
        'viscoplex-crude-oil-paraffin-inhibitors': {
          resourceType: ActionBarType.VIDEO,
          id: 'viscoplex-crude-oil-paraffin-inhibitors',
          title: 'VISCOPLEX® Crude Oil Paraffin Inhibitors (COPI)',
          link: 'https://www.youtube.com/embed/tw-wHCk6A08',
        },
        'viscoplex-ppd-experience': {
          resourceType: ActionBarType.VIDEO,
          id: 'viscoplex-ppd-experience',
          title: 'VISCOPLEX® PPD Experience',
          link: 'https://www.youtube.com/embed/Z7Wm3b5SWTU',
        },
        'viscoplex-ppds-in-action': {
          resourceType: ActionBarType.VIDEO,
          id: 'viscoplex-ppds-in-action',
          title: 'VISCOPLEX® PPDs in Action',
          link: 'hhttps://www.youtube.com/embed/nuT6X5sw41w',
        },
      }
    },
    // connect: {
    //   title: 'Connect',
    //   route: 'connect',
    //   isModal: true,
    //   icon: {
    //     height: 102,
    //     width: 159
    //   }
    // }
  }
}
