import { css } from '@emotion/css';

import { appVariables } from '../../config/variables';

export const styles = {
  pageHeading: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderTop: `4px solid ${appVariables.colors.purple}`,
    marginTop: 150
  }),
  iconContainer: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 60,
    height: 60,
    backgroundColor: appVariables.colors.purple
  }),
  title: css({
    fontFamily: appVariables.font.family,
    letterSpacing: appVariables.font.letterSpacing,
    marginLeft: 15,
    fontSize: 28,
    color: appVariables.colors.purple
  })
};
