import { css } from '@emotion/css';
  
export const styles = {
    video: css({
      position: 'fixed',
      right: 0,
      bottom: 0,
      minWidth: '100%',
      minHeight: '100%',
      maxWidth: '100%',
      backgroundColor: '#EB6002'
    }),
    loading: css({
      marginTop: 'calc(50vh - 25px)'
    })
 };
  