import React from 'react';
import { observer } from 'mobx-react-lite';
import { css } from '@emotion/css';

import PageHeadingComponent from '../../../shared/pageHeading/pageHeading.component';
import PagerComponent from '../../../shared/pager/pager.component';
import { styles } from '../../../../index.styles';
import { data } from '../../../assets/data/data';
import { IAppStore } from '../../../core/models';

const CalculatorsContainer = ({ store }: { store: IAppStore }) => {
  const pageData = data.pages.calculators;

  return (
    <div className={css(styles.container)}>
      <PageHeadingComponent
        ui={{
          page: 'calculators',
          title: pageData.title,
          icon: pageData.icon,
        }}
      />
      <PagerComponent
        ui={{
          children: pageData.children!,
          page: 'calculators',
          itemPath: 'tools'
        }}
      />
    </div>
  );
}

export default observer(CalculatorsContainer);