import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { css } from '@emotion/css';

import PageHeadingComponent from '../../pageHeading/pageHeading.component';
import { styles } from './modalSessionForm.styles';
import { SessionFormDetails } from '../../../core/models';
import { validateEmail } from '../../../utils';
import SessionStore from '../../../core/store/session.store';

const ModalSessionFormComponent = ({ ui, store }: {
  ui: {
    onSessionFormAction: (form: SessionFormDetails) => void;
  };
  store: SessionStore
}) => {  
  const [form, setForm] = useState<SessionFormDetails>({
    name: store.activeSession ? store.activeSession.name : '',
    company: store.activeSession ? store.activeSession.company : '',
    email: store.activeSession ? store.activeSession.email : '',
    notes: store.activeSession ? store.activeSession.notes : '',
    requestedItems: store.ui.itemLink && store.ui.itemTitle ? [{ name: store.ui.itemTitle, link: store.ui.itemLink }] : []
  });
  const [errors, setErrors] = useState<{ name?: string; email?: string; }>();
  
  function handleSendAction() {
    if (form.name === null || form.name === '') {
      setErrors({ ...errors, name: 'A name is required.' });
      return;
    }
  
    if (!form.email) {
      setErrors({ ...errors, email: 'An email is required.' });
    }

    if (form.email && !validateEmail(form.email)) {
      setErrors({ ...errors, email: 'You must enter a valid email address' });
      return;
    }

    // all passed, pass up the action
    ui.onSessionFormAction(form);
  }

  return(
    <div>
      <PageHeadingComponent
        ui={{
          style: { width: '90%', marginTop: 0, marginBottom: 40 },
          page: store.ui.modalPage,
          title: store.ui.modalTitle,
          icon: store.ui.modalIcon,
        }}
      />
      <div className={css(styles.inputs)}>
        <input
          placeholder='Name'
          className={css(styles.input, errors && errors.name ? styles.inputError : {})}
          onChange={(e) => setForm({ ...form, name: e.target.value })}
          value={form.name}
        />
        <input
          placeholder='Company'
          className={css(styles.input)}
          onChange={(e) => setForm({ ...form, company: e.target.value })}
          value={form.company}
        />
        <input
          placeholder='Email'
          className={css(styles.input, errors && errors.email ? styles.inputError : {})}
          onChange={(e) => setForm({ ...form, email: e.target.value })}
          value={form.email}
        />
        <textarea
          placeholder='Notes'
          className={css(styles.textarea)}
          onChange={(e) => setForm({ ...form, notes: e.target.value })}
          onSubmit={handleSendAction}
          value={form.notes}
        />
        {errors &&
          <div className={css(styles.errorsText)}>
            {errors.name && <p className={css(styles.inputErrorText)}>{errors.name}</p>}
            {errors.email && <p className={css(styles.inputErrorText)}>{errors.email}</p>}
          </div>
        }
      </div>
      <button
        className={css(styles.formButton)}
        onClick={handleSendAction}
      >
        <span className={css(styles.formButtonText)}>SEND</span>
      </button>
    </div>
  );
};

export default observer(ModalSessionFormComponent);