import React from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { css } from '@emotion/css';

import { data } from '../../../assets/data/data';
import PageHeadingComponent from '../../../shared/pageHeading/pageHeading.component';
import BlendingEfficiencyComponent from './calculators.blendingEfficiency.component';
import BaseOilMixturesComponent from './calculators.baseOilMixtures.component';
import ViscosityIndexComponent from './calculators.viscosityIndex.component';
import { styles } from '../../../../index.styles';

const CalculatorViewComponent = () => {
  const pageData = data.pages.calculators;
  const { id } = useParams<{
    id: 'blending-efficiency' | 'base-oil-mixtures' | 'viscosity-index'
  }>();

  function renderView() {
    switch (id) {
      case 'blending-efficiency':
        return <BlendingEfficiencyComponent data={pageData.children!['blending-efficiency']} />
      case 'base-oil-mixtures':
      return <BaseOilMixturesComponent data={pageData.children!['base-oil-mixtures']} />
      case 'viscosity-index':
      return <ViscosityIndexComponent data={pageData.children!['viscosity-index']} />
      default:
    }
  }

  return(
    <div className={css(styles.container)}>
      <PageHeadingComponent
        ui={{
          page: 'calculators',
          title: `Calculators | ${pageData.children![id].title}`,
          icon: pageData.icon,
        }}
      />
      {renderView()}
    </div>
  );
};

export default observer(CalculatorViewComponent);