import React from 'react';
import { observer } from 'mobx-react-lite';
import { css } from '@emotion/css';

import Images from '../../config/images';
import { styles } from './pageHeading.styles';

const PageHeadingComponent = ({ ui }: { ui: {
  page: string;
  icon: { [key: string]: any } | null;
  title: string;
  style?: { [key: string]: any }; 
}}) => {
  return(
    <div className={css(styles.pageHeading)} style={ui.style}>
      <div className={css(styles.iconContainer)}>
        <img
          style={{ width: ui.icon?.width / 5, height: ui.icon?.height / 5 }}
          src={Images[ui.page]}
          alt=''
        />
      </div>
      <p className={css(styles.title)}>{ui.title}</p>
    </div>
  );
};

export default observer(PageHeadingComponent);