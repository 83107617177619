import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { css } from '@emotion/css';

import Logo from '../../assets/files/icons/logo.svg';
import LogoPurple from '../../assets/files/icons/logo--purple.svg';
import Nav from '../../assets/files/icons/nav.svg';
import NavPurple from '../../assets/files/icons/nav--purple.svg';
import { styles } from './header.styles';
import { appStore } from '../../store';
import UIStore from '../../core/store/ui.store';

const HeaderComponent = ({ uiStore }: {
  uiStore: UIStore; 
}) => {
  return(
    <div className={
      css(
        uiStore.header.isOverlayed ? styles.headerOverlayed : styles.header,
      )}>
      <Link to={'/'} style={{ cursor: 'pointer' }}>
        {!uiStore.header.isOverlayed
          ? <img
              style={{
                width: 200,
                height: 51
              }}
              src={LogoPurple} alt="Evonik"
            />
          : <img
              style={{
                width: 200,
                height: 51
              }}
              src={Logo} alt="Evonik"
            />
        }
      </Link>
      {!uiStore.drawer.isOpen &&
        <button
          style={{ cursor: 'pointer' }}
          onClick={() => appStore.uiStore.updateDrawer({ isOpen: true })}
        >
          {!uiStore.header.isOverlayed
            ? <img
                style={{
                  width: 40,
                  height: 25,
                }}
                src={NavPurple} alt="nav"
              />
            : <img
              style={{
                width: 40,
                height: 25,
              }}
              src={Nav} alt="nav"
            />
        }
        </button>
      }
    </div>
  );
};

export default observer(HeaderComponent);