import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { IAppStore } from './core/models';
import { routes } from './config/config';
import DrawerContainer from './shared/drawer/containers/drawer.container';
import HeaderComponent from './shared/header/header.component';
import Listeners from './shared/listeners/listeners.component';
import ConnectModalContainer from './shared/modals/containers/connectModal.container';

const AppRouter = ({ store }: { store: IAppStore }) => {
  return (
    <Router>
      <Listeners store={store}/>
      {!store.uiStore.header.isHidden && <HeaderComponent uiStore={store.uiStore} />}
      <DrawerContainer uiStore={store.uiStore} />
      <Switch>
        {routes.map(x => <Route
            {...x.exact ? { exact: true } : {}}
            key={x.path}
            path={x.path}
            render={(props) => <x.component {...props} store={store} ui={{ page: x.page }} />}
          />)}
      </Switch>
      {store.sessionStore.ui.modalOpen && <ConnectModalContainer store={store} />}
    </Router>

  );
};

export default observer(AppRouter);