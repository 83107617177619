import React, { useState } from 'react';
import { css } from '@emotion/css';
import { observer } from 'mobx-react-lite';

import { BaseOilMixturesOne, BaseOilMixturesTwo } from '../models/baseOilMixtures';
import { styles } from '../calculators.styles';
import { baseOilMixturesTemp, calculateBaseOilMixturesOneApi, calculateBaseOilMixturesTwoApi } from '../api/calculators';
import Refresh from '../../../assets/files/icons/refresh.svg';

const initialStates: {
  baseOilMixturesOne: BaseOilMixturesOne,
  baseOilMixturesTwo: BaseOilMixturesTwo
} = {
  baseOilMixturesOne: {
    kv1: undefined,
    kv2: undefined,
    oil1: undefined,
    temp: +baseOilMixturesTemp[0].value,
    resultFinalKv: undefined,
    error: '',
  },
  baseOilMixturesTwo: {
    kv1: undefined,
    kv2: undefined,
    finalKv: undefined,
    temp: +baseOilMixturesTemp[0].value,
    resultOil1: undefined,
    resultOil2: undefined,
    error: '',
  }
};

const BaseOilMixturesComponent = ({ data }: { data: any }) => {
  const [baseOilMixturesOne, setBaseOilMixturesOne] = useState(initialStates.baseOilMixturesOne);
  const [baseOilMixturesTwo, setBaseOilMixturesTwo] = useState(initialStates.baseOilMixturesTwo);

  function changeBaseOilMixturesOne(field: string, value: number) {
    setBaseOilMixturesOne({
      ...baseOilMixturesOne,
      [field]: value
    });
  }

  function changeBaseOilMixturesTwo(field: string, value: number) {
    setBaseOilMixturesTwo({
      ...baseOilMixturesTwo,
      [field]: value
    });
  }

  function resetCalculator(calc: 'baseOilMixturesOne' | 'baseOilMixturesTwo') {
    if (calc === 'baseOilMixturesOne') {
      setBaseOilMixturesOne(initialStates.baseOilMixturesOne);
    } else {
      setBaseOilMixturesTwo(initialStates.baseOilMixturesTwo);
    }
  };

  function calculateBaseOilMixturesOne() {
    if (!baseOilMixturesOne.kv1 || !baseOilMixturesOne.kv2 || !baseOilMixturesOne.oil1) {
      setBaseOilMixturesOne({
        ...baseOilMixturesOne,
        error: 'Please enter some values first.',
      });
      return;
    }

    if (baseOilMixturesOne.kv1! <= 0 || baseOilMixturesOne.kv2! <= 0 || baseOilMixturesOne.oil1! < 0) {
      setBaseOilMixturesOne({
        ...baseOilMixturesOne,
        error: 'Your values are not coherent.',
      });
      return;
    }

    const result = calculateBaseOilMixturesOneApi(baseOilMixturesOne);

    setBaseOilMixturesOne({
      ...baseOilMixturesOne,
      error:  '',
      resultFinalKv: result.resultFinalKv
    });
  }

  function calculateBaseOilMixturesTwo() {
    if (!baseOilMixturesTwo.kv1 || !baseOilMixturesTwo.kv2 || !baseOilMixturesTwo.finalKv) {
      setBaseOilMixturesTwo({
        ...baseOilMixturesTwo,
        error: 'Please enter some values first.',
      });
      return;
    }

    if (baseOilMixturesTwo.kv1! <= 0 || baseOilMixturesTwo.kv2! <= 0 || baseOilMixturesTwo.finalKv! < 0) {
      setBaseOilMixturesTwo({
        ...baseOilMixturesTwo,
        error: 'Your values are not coherent.',
      });
      return;
    }

    const result = calculateBaseOilMixturesTwoApi(baseOilMixturesTwo);

    setBaseOilMixturesTwo({
      ...baseOilMixturesTwo,
      error:  '',
      resultOil1: result.resultOil1,
      resultOil2: result.resultOil2
    });
  }

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.tables, styles.tablesTop)}>
        <div className={css(styles.tableContainer)}>
          <h2 className={css(styles.tableTitle)}>{data.table1Title}</h2>
          <div className={css(styles.table)}>
            {/* Row 1 */}
            <div className={css(styles.tableRow)}>
              <div className={css(styles.tableLabel)}>
                <p className={css(styles.tableLabelText)}>Base Oil 1</p>
              </div>
              <div className={css(styles.tableField)}>
                <input
                  className={css(styles.tableFieldInput)}
                  maxLength={10}
                  placeholder=''
                  onChange={(e) => changeBaseOilMixturesOne('kv1', +e.target.value)}
                  value={baseOilMixturesOne.kv1 ? `${baseOilMixturesOne.kv1}` : ''}
                />
                <label className={css(styles.tableFieldText)}>cSt</label>
              </div>
            </div>
            {/* Row 2 */}
            <div className={css(styles.tableRow)}>
              <div className={css(styles.tableLabel)}>
                <p className={css(styles.tableLabelText)}>Base Oil 2</p>
              </div>
              <div className={css(styles.tableField)}>
                <input
                  className={css(styles.tableFieldInput)}
                  maxLength={10}
                  placeholder=''
                  onChange={(e) => changeBaseOilMixturesOne('kv2', +e.target.value)}
                  value={baseOilMixturesOne.kv2 ? `${baseOilMixturesOne.kv2}` : ''}
                />
                <label className={css(styles.tableFieldText)}>cSt</label>
              </div>
            </div>
            {/* Row 3 */}
            <div className={css(styles.tableRow)}>
              <div className={css(styles.tableLabel)}>
                <p className={css(styles.tableLabelText)}>Base Oil 1</p>
              </div>
              <div className={css(styles.tableField)}>
                <input
                  className={css(styles.tableFieldInput)}
                  maxLength={10}
                  placeholder='Enter ratio by weight of first base oil'
                  onChange={(e) => changeBaseOilMixturesOne('oil1', +e.target.value)}
                  value={baseOilMixturesOne.oil1 ? `${baseOilMixturesOne.oil1}` : ''}
                />
                <label className={css(styles.tableFieldText)}>cSt</label>
              </div>
            </div>
            {/* Row 4*/}
            <div className={css(styles.tableRow)}>
              <div className={css(styles.tableLabel)}>
                <p className={css(styles.tableLabelText)}>Temp</p>
              </div>
              <div className={css(styles.tableField)}>
                <select
                  className={css(styles.select)}
                  value={baseOilMixturesOne.temp}
                  onChange={(e) => changeBaseOilMixturesOne('temp', +e.target.value)}
                >
                  {baseOilMixturesTemp.map(x => (
                    <option key={`select-1-${x.label}`} value={x.value}>{x.label}</option>
                  ))}
                </select>
                <label className={css(styles.tableFieldText)}>°C</label>
              </div>
            </div>
            {/* Actions */}
            <div className={css(styles.tableActions)}>
              <button className={css(styles.tableAction, styles.tableActionLeft)} onClick={() => calculateBaseOilMixturesOne()}>
                <span className={css(styles.tableActionText)}>CALCULATE</span>
              </button>
              <button className={css(styles.tableAction)} onClick={() => resetCalculator('baseOilMixturesOne')}>
                <img className={css(styles.tableActionIcon)} src={Refresh} alt='refresh' />
                <span className={css(styles.tableActionText)}>RESET</span>
              </button>
            </div>
          </div>
          {/* Results & Errors */}
          {baseOilMixturesOne.error  &&
            <div className={css(styles.tableError)}>
              <p className={css(styles.tableErrorText)}>Error {baseOilMixturesOne.error }</p>
            </div>
          }
          <div className={css(
            styles.tableResults,
            baseOilMixturesOne.resultFinalKv ? styles.tableResultsActive : {},
          )}>
            <div className={css(styles.tableResultsLabel)}>
              <p className={css(styles.tableResultsText)}>RESULTS</p>
            </div>
            <div className={css(styles.tableResultsValues)}>
              <p className={css(styles.tableResultsText)}>
                Final KV: {baseOilMixturesOne.resultFinalKv && `${baseOilMixturesOne.resultFinalKv} cSt`}
              </p>
            </div> 
          </div>
        </div>

        <div className={css(styles.tableContainer)}>
          <h2 className={css(styles.tableTitle)}>{data.table1Title}</h2>
          <div className={css(styles.table)}>
            {/* Row 1 */}
            <div className={css(styles.tableRow)}>
              <div className={css(styles.tableLabel)}>
                <p className={css(styles.tableLabelText)}>Base Oil 1</p>
              </div>
              <div className={css(styles.tableField)}>
                <input
                  className={css(styles.tableFieldInput)}
                  maxLength={10}
                  placeholder='Enter as mm²/s'
                  onChange={(e) => changeBaseOilMixturesTwo('kv1', +e.target.value)}
                  value={baseOilMixturesTwo.kv1 ? `${baseOilMixturesTwo.kv1}` : ''}
                />
                <label className={css(styles.tableFieldText)}>cSt</label>
              </div>
            </div>
            {/* Row 2 */}
            <div className={css(styles.tableRow)}>
              <div className={css(styles.tableLabel)}>
                <p className={css(styles.tableLabelText)}>Base Oil 2</p>
              </div>
              <div className={css(styles.tableField)}>
                <input
                  className={css(styles.tableFieldInput)}
                  maxLength={10}
                  placeholder='Enter as mm²/s'
                  onChange={(e) => changeBaseOilMixturesTwo('kv2', +e.target.value)}
                  value={baseOilMixturesTwo.kv2 ? `${baseOilMixturesTwo.kv2}` : ''}
                />
                <label className={css(styles.tableFieldText)}>cSt</label>
              </div>
            </div>
            {/* Row 3 */}
            <div className={css(styles.tableRow)}>
              <div className={css(styles.tableLabel)}>
                <p className={css(styles.tableLabelText)}>Final KV</p>
              </div>
              <div className={css(styles.tableField)}>
                <input
                  className={css(styles.tableFieldInput)}
                  maxLength={10}
                  placeholder='Enter as mm²/s'
                  onChange={(e) => changeBaseOilMixturesTwo('finalKv', +e.target.value)}
                  value={baseOilMixturesTwo.finalKv ? `${baseOilMixturesTwo.finalKv}` : ''}
                />
                <label className={css(styles.tableFieldText)}>cSt</label>
              </div>
            </div>
            {/* Row 4*/}
            <div className={css(styles.tableRow)}>
              <div className={css(styles.tableLabel)}>
                <p className={css(styles.tableLabelText)}>Temp</p>
              </div>
              <div className={css(styles.tableField)}>
                <select
                  className={css(styles.select)}
                  value={baseOilMixturesTwo.temp}
                  onChange={(e) => changeBaseOilMixturesTwo('temp', +e.target.value)}
                >
                  {baseOilMixturesTemp.map(x => (
                    <option key={`select-2-${x.label}`} value={x.value}>{x.label}</option>
                  ))}
                </select>
                <label className={css(styles.tableFieldText)}>°C</label>
              </div>
            </div>
            {/* Actions */}
            <div className={css(styles.tableActions)}>
              <button className={css(styles.tableAction, styles.tableActionLeft)} onClick={() => calculateBaseOilMixturesTwo()}>
                <span className={css(styles.tableActionText)}>CALCULATE</span>
              </button>
              <button className={css(styles.tableAction)} onClick={() => resetCalculator('baseOilMixturesTwo')}>
                <img className={css(styles.tableActionIcon)} src={Refresh} alt='refresh' />
                <span className={css(styles.tableActionText)}>RESET</span>
              </button>
            </div>
          </div>
          {/* Results & Errors */}
          {baseOilMixturesTwo.error  &&
            <div className={css(styles.tableError)}>
              <p className={css(styles.tableErrorText)}>Error {baseOilMixturesTwo.error }</p>
            </div>
          }
          <div className={css(
            styles.tableResults,
            baseOilMixturesOne.resultFinalKv ? styles.tableResultsActive : {},
          )}>
            <div className={css(styles.tableResultsLabel)}>
              <p className={css(styles.tableResultsText)}>RESULTS</p>
            </div>
            <div className={css(styles.tableResultsValues)}>
              <p className={css(styles.tableResultsText)}>
                Base Oil 1: {baseOilMixturesTwo.resultOil1 && `${baseOilMixturesTwo.resultOil1}%`}
              </p>
              <p className={css(styles.tableResultsText, styles.tableResultsValueRight)}>
                Base Oil 1: {baseOilMixturesTwo.resultOil2 && `${baseOilMixturesTwo.resultOil2}%`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(BaseOilMixturesComponent);