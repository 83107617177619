import { css } from '@emotion/css';
  
export const styles = {
  header: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100vw',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    height: 85,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 9,
    backgroundColor: 'white'
  }),
  headerOverlayed: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100vw',
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    height: 85,
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 9,
    backgroundColor: 'transparent'
  }),
  overlay: css({
    opacity: 0,
  })
};
