import React from 'react';
import { css } from '@emotion/css';

import Images from '../../config/images';
import { styles } from './drawer.styles';
import { Link } from 'react-router-dom';
import { appStore } from '../../store';

const DrawerIemComponent = ({ ui }: { ui: {
  item: { [key: string]: any };
  itemKey: string;
  key: string;
}}) => {
  function renderContent() {
    return <>
      <img
        style={{ marginTop: 4, width: ui.item.icon.width / 3.75, height: ui.item.icon.height / 3.75 }}
        src={Images[ui.itemKey]}
        alt={ui.itemKey}
      />
      <span className={css(styles.itemText)}>{ui.item.title}</span>
    </>;
  }

  return (
    <>
      {ui.item.externalRoute
        ? <a
            className={css(styles.item)}
            href={ui.item.externalRoute}
            target='_blank'
            rel='noreferrer'
          >
            {renderContent()}
          </a>
        : ui.item.isModal
            ? <button
                onClick={() => appStore.sessionStore.updateUI({
                  modalTitle: ui.item.title,
                  modalPage: ui.item.page,
                  modalIcon: ui.item.icon,
                  itemLink: '',
                  itemTitle: '',
                  modalOpen: true,
                })}
                className={css(styles.item)}
              >
                {renderContent()}
              </button>
            : <Link
                to={`/${ui.item.route}`}
                className={css(styles.item)}
              >
                {renderContent()}
              </Link>
      }
    </>
  )
};

export default DrawerIemComponent;
