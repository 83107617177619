import { css } from '@emotion/css';

export const styles = {
  app: css({
    overflowX: 'hidden',
    width: '100vw'
  }),
  container: css({
    paddingLeft: 30,
    paddingRight: 30
  }),
  pagerLoading: css({
    marginTop: 'calc(50vh - 50px)'
  })
};
