import { css } from '@emotion/css';
  
import { appVariables } from '../../config/variables';

export const styles = {
  actionBar: css({
    flex: 1,
    width: '100vw',
    zIndex: 8,
    display:'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 30,
    paddingRight: 30,
    position: 'fixed',
    top: 0
  }),
  actionBarDark: css({
    height: 60,
    maxHeight: 60,
    backgroundColor: 'black'
  }),
  actionBarLight: css({
    height: 60,
    maxHeight: 60,
    backgroundColor: appVariables.colors.lightGray
  }),
  title: css({
    fontFamily: appVariables.font.family,
    fontSize: 21,
    letterSpacing: appVariables.font.letterSpacing,
  }),
  titleLight: css({
    color: appVariables.colors.lightestGray
  }),
  titleDark: css({
    color: 'black',
  }),
  titleElip: css({
    width: 'calc(100vw - 125px)'
  })
};
