import React from 'react';
import { css } from '@emotion/css';

import { styles } from './pager.styles';
import PagerGroupContainerComponent from './pagerGroupContainer.component'

const PagerComponent = ({ ui }: { ui: {
  children: { [key: string]: any };
  page: string;
  itemPath: string;
}}) => {
  return (
    <div className={css(styles.pager)}>
      <PagerGroupContainerComponent ui={{ children: ui.children, page: ui.page, itemPath: ui.itemPath }} />
    </div>
  );
};

export default PagerComponent;