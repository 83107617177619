import React from 'react';
import { observer } from 'mobx-react-lite';
import { css } from '@emotion/css';

import ActionBarComponent from '../actionBar/actionBar.component';
import { ActionBarType } from '../actionBar/enums/actionBarType';
import Videos from '../../config/videos';

import { styles } from './resource.styles';
import { IAppStore } from '../../core/models';
import { useParams } from 'react-router-dom';
import { data } from '../../assets/data/data';
import { Child, Page } from '../../models';

const ResourceVideoContainer = ({ store, ui }: {
  store: IAppStore;
  ui: { page: keyof Page; };
}) => {
  const { id } = useParams<{ id: string; }>();
  const resourceData = data.pages[ui.page ].children![id as keyof Child];

  return (
    <div className={css(styles.videoContainer)}>
      <ActionBarComponent
        ui={{
          type: ActionBarType.VIDEO,
          displayEmailAction: !store.sessionStore.ui.turnOffEmailing,
          page: ui.page,
          title: resourceData.title,
          link: resourceData.link || ''
        }}
        store={store.uiStore}
      />
      <div className={css(styles.videoView)}>
        <iframe
          className={css(styles.videoViewVideo)}
          title='Evonik Video'
          width='560'
          height='315'
          src={Videos[resourceData.id]}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      </div>
    </div>
  );
}

export default observer(ResourceVideoContainer);

