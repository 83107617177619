export default {
'copi-technology-crude-oil-paraffin-inhibitors': '/assets/files/pdfs/copi-technology-crude-oil-paraffin-inhibitors.pdf',
'demonstrating-system-efficiency-of-construction-equipment': '/assets/files/pdfs/demonstrating-system-efficiency-of-construction-equipment.pdf',
'drivon-technology-fuel-efficient-powertrain-solutions': '/assets/files/pdfs/drivon-technology-fuel-efficient-powertrain-solutions.pdf',
'dynavis-technology-resource-efficient-hydraulic-additives': '/assets/files/pdfs/dynavis-technology-resource-efficient-hydraulic-additives.pdf',
'efficiency-gains-in-construction-manufacturing-and-mining-have-become-reality-with-dynavis-technology': '/assets/files/pdfs/efficiency-gains-in-construction-manufacturing-and-mining-have-become-reality-with-dynavis-technology.pdf',
'evoniks-viscoplex-additives-and-viscobase-base-oils': '/assets/files/pdfs/evoniks-viscoplex-additives-and-viscobase-base-oils.pdf',
'hydraulic-fluids-a-treatise-on-formula-for-performance-and-efficiency': '/assets/files/pdfs/hydraulic-fluids-a-treatise-on-formula-for-performance-and-efficiency.pdf',
'impact-of-vi-improver-on-the-formation-of-piston-deposits-in-fuel-efficient-engine-oils': '/assets/files/pdfs/impact-of-vi-improver-on-the-formation-of-piston-deposits-in-fuel-efficient-engine-oils.pdf',
'nuflux-technology': '/assets/files/pdfs/nuflux-technology.pdf',
'oil-additives-product-application-guide': '/assets/files/pdfs/oil-additives-product-application-guide.pdf',
'ppd-considerations-for-sae-0w-16-formulations': '/assets/files/pdfs/ppd-considerations-for-sae-0w-16-formulations.pdf',
'robo-as-a-formulation-tool-for-engine-oil-development': '/assets/files/pdfs/robo-as-a-formulation-tool-for-engine-oil-development.pdf',
'the-benefits-of-novel-functionalized-viscosity-index-improvers-in-wet-clutch-systems': '/assets/files/pdfs/the-benefits-of-novel-functionalized-viscosity-index-improvers-in-wet-clutch-systems.pdf',
'viscobase-synthetic-base-fluids': '/assets/files/pdfs/viscobase-synthetic-base-fluids.pdf',
'viscoplex-additives-for-hydraulic-fluids-used-in-manufacturing-equipment': '/assets/files/pdfs/viscoplex-additives-for-hydraulic-fluids-used-in-manufacturing-equipment.pdf',
'viscoplex-additives-for-mobile-hydraulic-equipment': '/assets/files/pdfs/viscoplex-additives-for-mobile-hydraulic-equipment.pdf',
'viscoplex-cold-flow-improvers': '/assets/files/pdfs/viscoplex-cold-flow-improvers.pdf',
'viscoplex-pour-point-depressant-technologies': '/assets/files/pdfs/viscoplex-pour-point-depressant-technologies.pdf',
'viscoplex-vii-shock-and-steering': '/assets/files/pdfs/viscoplex-vii-shock-and-steering.pdf', 'viscoplex-additives-for-hydraulic-and-utto-fluids-in-agriculture-and-forestry': '/assets/files/pdfs/viscoplex-additives-for-hydraulic-and-utto-fluids-in-agriculture-and-forestry.pdf'} as { [key: string]: any };