import { observable, action, makeObservable } from 'mobx';
import { sendRequestApi } from '../api';

import { Session } from '../models';

export default class SessionStore {
  activeSession?: Session;
  request?: Session;
  requestStatus = false;
  ui = {
    modalOpen: false,
    modalTitle: '',
    modalIcon: null,
    modalPage: '',
    itemLink: '',
    itemTitle: '',
    contactSent: false,
    turnOffEmailing: true
  }

  constructor() {
    makeObservable(this, {
      activeSession: observable,
      request: observable,
      ui: observable,
      requestStatus: observable,
      sendRequests: action,
      updateUI: action,
      updateActiveSession: action,
    });
  }

  async sendRequests() {
    this.requestStatus = await sendRequestApi();
  }

  updateUI(ui: any) {
    this.ui = {
      ...this.ui,
      ...ui
    }
  }

  updateActiveSession(session: Session) {
    this.activeSession = session;
  }

  updateRequest(request?: Session) {
    this.request = request;
  }
}