import { css } from '@emotion/css';
  
import { appVariables } from '../../config/variables';

export const styles = {
  container: css({
    position: 'fixed',
    transition: 'right 0.3s ease-out',
    right: -300,
    top: 0,
    paddingTop: 20,
    paddingBottom: 20,
    zIndex: 10,
    overflow: 'hidden',
    backgroundColor:'rgba(255, 255, 255, 0.8)',
    height: '100vh',
    width: 300,
  }),
  containerOpen: css({
    right: 0,
  }),
  content: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  }),
  close: css({
    alignItems: 'center',
    marginTop: 20,
    cursor: 'pointer'
  }),
  items: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  item: css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems:'center',
    marginTop: 4,
    marginBottom: 4,
    width: 80,
    height: 80,
    maxHeight: 80,
    backgroundColor: appVariables.colors.purple,
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'black'
    }
  }),
  itemDisabled: css({
    opacity: .3
  }),
  itemText: css({
    display: 'block',
    marginTop: 8,
    color: 'white',
    fontSize: 11,
  }),
  contactInfo: css({
    justifySelf: 'flex-end',
    fontSize: 14,
    marginBottom: 20,
    lineHeight: 1.3
  }),
  contactInfoIcons: css({
    textAlign: 'center',
    marginTop: 8,
    cursor: 'pointer',
    'a:not(:last-child)': {
      marginRight: 10
    }
  }),
  contactInfoText: css({
    textAlign: 'center',
    letterSpacing: 0.2,
    fontWeight: 300,
    'a, a:visited': {
      cursor: 'pointer',
      color: 'black',
    },
    'a:hover': {
      color: appVariables.colors.purple,
    }
  })
};

