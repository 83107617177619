import { observable, action, makeObservable } from 'mobx';

export default class RouterStore {
  location!: any;

  constructor() {
    makeObservable(this, {
      location: observable,
      updateLocation: action
    });
  }

  updateLocation(loc: any) {
    this.location = loc;
  }
}
