import { IAppStore } from './core/models';
import RouterStore from './core/store/router.store';
import SessionStore from './core/store/session.store';
import UIStore from './core/store/ui.store';

export const appStore: IAppStore = {
  routerStore: new RouterStore(),
  sessionStore: new SessionStore(),
  uiStore: new UIStore(),
};
