import { observer } from 'mobx-react-lite';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { IAppStore } from '../../core/models';

import { appStore } from '../../store';

const Listeners = ({ store }: { store: IAppStore }) => {
  const location = useLocation();
  // const history = useHistory();

  // console.log(location.pathname, store.routerStore.location?.pathname);
  if (location.pathname !== store.routerStore.location?.pathname) {
    appStore.routerStore.updateLocation(location);
  }

  return <></>
};

export default observer(Listeners);