import { appStore } from '../../../store';
import { Session } from '../../models';

export function sendRequest(request?: Session) {
  if (!request) {
    return;
  }

  appStore.sessionStore.sendRequests();
}
