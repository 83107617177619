import React from 'react';
import { observer } from 'mobx-react-lite';
import { css } from '@emotion/css';

import ModalSessionFormComponent from '../components/modalSessionForm.component';
import { styles } from '../modals.styles';
import { IAppStore, SessionFormDetails } from '../../../core/models';
import { appStore } from '../../../store';
import Close from '../../../assets/files/icons/close.svg';

const ConnectModalContainer = ({ store }: {
  store: IAppStore;
}) => {  
  function handleFormSubmit(details: SessionFormDetails) {
    // return without processing when already submitted contact form
    if (store.sessionStore.ui.contactSent) {
      handleExit();
      return;
    }

    appStore.sessionStore.updateRequest({
      ...details,
      contact: details.requestedItems.length === 0
    });

    handleExit();
  }

  function handleExit() {
    appStore.sessionStore.updateUI({
      modalTitle: '',
      modalPage: '',
      modalIcon: '',
      modalOpen: false,
    })
  }

  return(
    <div className={css(styles.modalContainer)}>
      <div className={css(styles.modalContentContainer)}>
        <ModalSessionFormComponent
          ui={{
            onSessionFormAction: (details: SessionFormDetails) => handleFormSubmit(details),
          }}
          store={store.sessionStore}
        />
        <button className={css(styles.exitButton)} onClick={handleExit}>
          <img
            style={{ width: 25, height: 25 }}
            src={Close}
            alt='close'
          />
        </button>
      </div>
    </div>
  );
};

export default observer(ConnectModalContainer);