import React from 'react';
import ReactDOM from 'react-dom';
import { css } from '@emotion/css';

import reportWebVitals from './reportWebVitals';
import AppRouter from './app/router';
import { appStore } from './app/store';

import 'normalize.css';
import './index.styles.css';
import { styles } from './index.styles';

// side effects
import './app/core/store/reactions';

ReactDOM.render(
  <React.StrictMode>
    <div className={css(styles.app)}>
      <AppRouter store={appStore} />
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
