import { css } from '@emotion/css';
  
export const styles = {
  modalContainer: css({
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 10,
    height: '100vh',
    width: '100vw',
    backgroundColor: 'rgba(171, 171, 171, 0.85)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }),
  modalContentContainer: css({
    padding: 30,
    width:  '50vw',
    backgroundColor: 'white',
    position: 'relative'
  }),
  exitButton: css({
    position: 'absolute',
    top: 30,
    right: 30,
  })
};
