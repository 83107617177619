import { appConfig } from '../../config/config';
import { appStore } from '../../store';

export function sendRequestApi() {
  const config = process.env.NODE_ENV === 'development'
    ? appConfig.development
    : appConfig.production;

  try {
    return fetch(`${config.apiPath}/send-emails`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Session-Token': config.apiToken,
      },
      body: JSON.stringify([appStore.sessionStore.request]),
    })
    .then(response => response.json())
    .then(() => true);
  } catch (err) {
    throw err;
  }
}
