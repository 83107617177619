import React from 'react';
import { observer } from 'mobx-react-lite';
import { css } from '@emotion/css';

import DrawerItemComponent  from '../drawerItem.component';
import Close from '../../../assets/files/icons/close.svg';
import Youtube from '../../../assets/files/icons/youtube.svg';
import LinkedIn from '../../../assets/files/icons/linkedin.svg';
import Twitter from '../../../assets/files/icons/twitter.svg';
import { styles } from '../drawer.styles';
import { data } from '../../../assets/data/data';
import { appStore } from '../../../store';
import UIStore from '../../../core/store/ui.store';

const DrawerContainer = ({ uiStore }: {
  uiStore: UIStore
}) => {  
  function renderItems() {
    const pages = data.pages;
    return Object.keys(pages).map(key =>
      <DrawerItemComponent
        key={`item-${key}`}
        ui={{
          item: (pages as any)[key],
          itemKey: key,
          key: `item-${key}`,
        }}
      />);
  }

  return (
    <div
      className={css(styles.container, uiStore.drawer.isOpen && styles.containerOpen)}>
      <div className={css(styles.content)}>
        <button
          className={css(styles.close)}
          onClick={() => appStore.uiStore.updateDrawer({ isOpen: false })}
        >
          <img
            style={{ width: 25, height: 25 }}
            src={Close}
            alt='close'
          />
        </button>
        <div className={css(styles.items)}>
          {renderItems()}
        </div>
        <div className={css(styles.contactInfo)}>
          <div className={css(styles.contactInfoText)}>
            <p>
              <a target="_blank" rel="noreferrer" href="https://oil-additives.evonik.com/en/contact"><b>CONTACT</b></a>
              &nbsp; <b>|</b> &nbsp;
              <a target="_blank" rel="noreferrer" href="mailto:oil-additives@evonik.com"><b>EMAIL</b></a>
            </p>
          </div>
          <div className={css(styles.contactInfoIcons)}>
            <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/showcase/evonik-industries-ag---business-line-oil-additives/?viewAsMember=true">
              <img
                style={{ width: 25, height: 25 }}
                src={LinkedIn}
                alt='LinkedIn'
              />
            </a>
            <a target="_blank" rel="noreferrer" href="https://twitter.com/EvonikOilAdds">
              <img
                style={{ width: 25, height: 25 }}
                src={Twitter}
                alt='Twitter'
              />
            </a>
            <a target="_blank" rel="noreferrer" href="ttps://www.youtube.com/channel/UCtj92BgGjgnJKuLzPffTq6g">
              <img
                style={{ width: 25, height: 25 }}
                src={Youtube}
                alt='Youtube'
              />
            </a>`
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(DrawerContainer);