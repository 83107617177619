import { IRoute } from '../models';

import EntryContainer from '../features/entry/containers/entry.container';
import LiteratureContainer from '../features/literature/containers/literature.container';
import VideosContainer from '../features/videos/containers/videos.container';
import PresentationsContainer from '../features/presentations/containers/presentations.container';
import CalculatorsContainer from '../features/calculators/containers/calculators.container';
import CalculatorViewComponent from '../features/calculators/components/calculatorView.component';
import ResourcePdfContainer from '../shared/resource/resourcePdf.container';
import ResourceVideoContainer from '../shared/resource/resourceVideo.container';
// import AdminContainer from './features/admin/containers/admin.container';

export const routes: IRoute[] = [
  {
    path: '/',
    component: EntryContainer,
    exact: true,
    headerOverlayed: true,
    page: 'entry',
  },
  {
    path: '/literature',
    component: LiteratureContainer,
    exact: true,
    headerOverlayed: false,
    page: 'literature',
  },
  {
    path: '/literature/resource/:id',
    component: ResourcePdfContainer,
    exact: true,
    headerOverlayed: false,
    page: 'literature',
  },
  {
    path: '/videos',
    component: VideosContainer,
    exact: true,
    headerOverlayed: false,
    page: 'videos',
  },
  {
    path: '/videos/resource/:id',
    component: ResourceVideoContainer,
    exact: true,
    headerOverlayed: false,
    page: 'videos',
  },
  {
    path: '/presentations',
    component: PresentationsContainer,
    exact: true,
    headerOverlayed: false,
    page: 'presentations',
  },
  {
    path: '/presentations/resource/:id',
    component: ResourcePdfContainer,
    exact: true,
    headerOverlayed: false,
    page: 'presentations',
  },
  {
    path: '/calculators',
    component: CalculatorsContainer,
    exact: true,
    headerOverlayed: false,
    page: 'calculators',
  },
  {
    path: '/calculators/tools/:id',
    component: CalculatorViewComponent,
    exact: true,
    headerOverlayed: false,
    page: 'calculators',
  }
];
 
export const appConfig = {
  development: {
    apiPath: 'https://protected-shelf-25534.herokuapp.com',
    apiToken: '',
  },
  production: {
    apiPath: 'https://dry-temple-88085.herokuapp.com',
    apiToken: '',
  }
};