import React from 'react';
import { observer } from 'mobx-react-lite';
import { css } from '@emotion/css';

import { ActionBarType } from './enums/actionBarType';
import { styles } from './actionBar.styles';
import { Page } from '../../models';
import Email from '../../assets/files/icons/email.svg';
import EmailGray from '../../assets/files/icons/email--gray.svg';
import Close from '../../assets/files/icons/close.svg';
import CloseGray from '../../assets/files/icons/close--gray.svg';
import { useHistory } from 'react-router-dom';
import { appStore } from '../../store';
import UIStore from '../../core/store/ui.store';
import { data } from '../../assets/data/data';

const ActionBarComponent = ({ store, ui }: {
  ui: {
    type: ActionBarType;
    displayEmailAction: boolean;
    page: keyof Page;
    title: string;
    link: string;
  },
  store: UIStore
}) => {
  const history = useHistory();
  const pageData = data.pages[store.currentPage];
    
  function handleEmailAction() {

    // setup modal details and set to open
    appStore.sessionStore.updateUI({
      modalTitle: pageData.title,
      modalPage: pageData.route,
      modalIcon: pageData.icon,
      itemLink: ui.link,
      itemTitle: ui.title,
      modalOpen: true,
    })
  }

  return(
    <div className={css(
      styles.actionBar,
      ui.type === 'video' ? styles.actionBarDark : styles.actionBarLight
    )}>
      {ui.displayEmailAction &&
        <button style={{ cursor: 'pointer' }} onClick={handleEmailAction}>
          <img
            style={{
              width: 50,
              height: 22,
            }}
            src={ui.type === 'video' ? EmailGray : Email}
            alt='action'
          />
        </button>
      }
      <p
        className={css(
          styles.title,
          !ui.displayEmailAction ? styles.titleElip : {},
          ui.type === 'video' ? styles.titleLight : styles.titleDark,
        )}
      >
        {ui.title}
      </p>
      <button style={{ cursor: 'pointer' }} onClick={() => history.push(`/${store.currentPage}`)}>
        <img
          style={{
            width: 22,
            height: 22,
          }}
          src={ui.type === 'video' ? CloseGray : Close}
          alt='action'
        />
      </button>
    </div>
  );
};

export default observer(ActionBarComponent);