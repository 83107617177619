import React, { useState } from 'react';

import { chunk } from '../../utils';
import PagerGroup from './pagerGroup.component';
import PagerDotsComponent from './pagerDots.component';

const PagerGroupContainerComponent = ({ ui }: { ui: {
  children: { [key: string]: any };
  page: string;
  itemPath: string;
}}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const chunks = chunk(Object.keys(ui.children), 4);

  function renderGroup(chunk: string[], idx: number) {
    if (currentIndex === idx) {
      return <PagerGroup key={`pager-group-${idx}`} ui={{
        chunk,
        page: ui.page,
        children: ui.children,
        itemPath: ui.itemPath,
      }} />
    }
  }

  return (
    <>
      {chunks.map((chunk: string[], i: number) => (
        renderGroup(chunk, i)
      ))}
      {chunks.length > 1 &&
        <PagerDotsComponent ui={{
            chunks,
            page: ui.page,
            setCurrent: (idx) => setCurrentIndex(idx),
            currentIndex
        }} />
      }
    </>
  );
};

export default PagerGroupContainerComponent;