import { Location } from 'history';

import { appStore } from '../../../store';

export function routeChanged(location: Location) {
  appStore.uiStore.updateDrawer({
    isOpen: false,
  });

  appStore.uiStore.updateCurrentPage(location.pathname.split('/')[1]);

  if (location.pathname.includes('resource')) {
    appStore.uiStore.updateHeader({
      isHidden: true,
    });
  } else {
    appStore.uiStore.updateHeader({
      isHidden: false,
    });
  }

  if (location.pathname === '/') {
    appStore.uiStore.updateHeader({
      isOverlayed: true,
    });
  } else {
    appStore.uiStore.updateHeader({
      isOverlayed: false,
    });
  }
}