import { css } from '@emotion/css';

import { appVariables } from '../../config/variables';

export const styles = {
  pager: css({
   marginTop: 40
  }),
  pagerGroup: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: 'calc(100vw - 60px)',
    paddingLeft: 78,
    paddingRight: 78,
  }),
  loading: css({
    marginTop: 'calc((100vh / 2) - 150px)'
  }),
  pagerDots: css({
    marginTop: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  }),
  pagerDot: css({
    flex: 1,
    marginLeft: 3,
    marginRight: 3,
    maxWidth: 14,
    height: 14,
    borderRadius: 100 / 2,
    backgroundColor: appVariables.colors.mediumGray,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: appVariables.colors.purple
    }
  }),
  pagerDotActive: css({
    backgroundColor: appVariables.colors.purple
  }),
  pagerItem: css({
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    maxWidth: 'calc(50vw - 118px)',
    fontFamily: appVariables.font.family,
    fontSize: 18,
    letterSpacing: appVariables.font.letterSpacing,
    cursor: 'pointer',
    '&:nth-child(odd)': {
      marginRight: 10
    },
    '&:nth-child(even)': {
      marginLeft: 10
    },
    '&:nth-child(3), &:nth-child(4)': {
      marginTop: 20
    },
    '&:visited': {
      color: 'black',
      fontFamily: appVariables.font.family,
      fontSize: 18,
      letterSpacing: appVariables.font.letterSpacing
    }
  }),
  pagerThumbnail: css({
    flex: 1,
    width: '25vw',
    maxWidth: '25vw',
    height: '25vh',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  }),
  pagerTextContainer: css({
    flex: 1,
    width: '25vw',
    maxWidth: '25vw',
    backgroundColor: appVariables.colors.mediumGray,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 20,
    paddingRight: 20,
  })
};
