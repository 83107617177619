import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { css } from '@emotion/css';

import { blendingEfficiencyVii, calculateBlendingEfficiencyOneApi, calculateBlendingEfficiencyTwoApi } from '../api/calculators';
import { BlendingEfficiencyOne, BlendingEfficiencyTwo } from '../models/blendingEfficiency';
import { styles } from '../calculators.styles';
import Refresh from '../../../assets/files/icons/refresh.svg';

const initialStates: {
  blendingEfficiencyOne: BlendingEfficiencyOne;
  blendingEfficiencyTwo: BlendingEfficiencyTwo;
} = {
  blendingEfficiencyOne: {
    base: undefined,
    blend: undefined,
    vii: blendingEfficiencyVii[0].value,
    resultVii: undefined,
    resultBase: undefined,
    error: ''
  },
  blendingEfficiencyTwo: {
    base: undefined,
    blend: undefined,
    vii: blendingEfficiencyVii[0].value,
    resultVii: undefined,
    resultBase: undefined,
    error: ''
  }
};

const BlendingEfficiencyComponent = ({ data }: { data: any }) => {
  const [blendingEfficiencyOne, setBlendingEfficiencyOne] = useState(initialStates.blendingEfficiencyOne);
  const [blendingEfficiencyTwo, setBlendingEfficiencyTwo] = useState(initialStates.blendingEfficiencyTwo);

  function changeBlendingEfficiencyOne(field: string, value: number) {
    setBlendingEfficiencyOne({
      ...blendingEfficiencyOne,
      [field]: value
    });
  }

  function changeBlendingEfficiencyTwo(field: string, value: number) {
    setBlendingEfficiencyTwo({
      ...blendingEfficiencyTwo,
      [field]: value
    });
  }

  function resetCalculator(calc: 'blendingEfficiencyOne' | 'blendingEfficiencyTwo') {
    if (calc === 'blendingEfficiencyOne') {
      setBlendingEfficiencyOne(initialStates.blendingEfficiencyOne);
    } else {
      setBlendingEfficiencyTwo(initialStates.blendingEfficiencyTwo);
    }
  };

  function calculateBlendingEfficiencyOne() {
    if (!blendingEfficiencyOne.base || !blendingEfficiencyOne.blend) {
      setBlendingEfficiencyOne({
        ...blendingEfficiencyOne,
        error: 'Please enter some values first.',
      });
      return;
    }

    if (blendingEfficiencyOne.base <= 0 || blendingEfficiencyOne.blend <= 0) {
      setBlendingEfficiencyOne({
        ...blendingEfficiencyOne,
        error: 'Your values are not coherent.',
      });
      return;
    }

    const result = calculateBlendingEfficiencyOneApi(blendingEfficiencyOne);
    if (!result) {
      setBlendingEfficiencyOne({
        ...blendingEfficiencyOne,
        error: 'Something went wrong.',
      });
      return;
    }

    setBlendingEfficiencyOne({
      ...blendingEfficiencyOne,
      error: '',
      resultVii: result.resultVii,
      resultBase: result.resultBase
    });
  }

  function calculateBlendingEfficiencyTwo() {
    if (!blendingEfficiencyTwo.base || !blendingEfficiencyTwo.percent) {
      setBlendingEfficiencyTwo({
        ...blendingEfficiencyTwo,
        error: 'Please enter some values first.'
      });
      return;
    }

    if (blendingEfficiencyTwo.base <= 0 || blendingEfficiencyTwo.percent <= 0) {
      setBlendingEfficiencyTwo({
        ...blendingEfficiencyTwo,
        error: 'Your values are not coherent.'
      });
      return;
    }

    const result = calculateBlendingEfficiencyTwoApi(blendingEfficiencyTwo);
    if (!result || !result.resultBlend) {
      setBlendingEfficiencyTwo({
        ...blendingEfficiencyTwo,
        error: 'Something went wrong.'
      });
      return;
    }

    setBlendingEfficiencyTwo({
      ...blendingEfficiencyTwo,
      error: '',
      resultBlend: result.resultBlend
    });
  }

  return (
    <div className={css(styles.container)}>
      <p className={css(styles.description)}>{data.description}</p>
      
      <div className={css(styles.tables)}>
        <div className={css(styles.tableContainer)}>
          <div className={css(styles.table)}>
            {/* Row 1 */}
            <div className={css(styles.tableRow)}>
              <div className={css(styles.tableLabel)}>
                <p className={css(styles.tableLabelText)}>Base Oil</p>
              </div>
              <div className={css(styles.tableField)}>
                <input
                  className={css(styles.tableFieldInput)}
                  maxLength={10}
                  placeholder="Viscosity at"
                  onChange={(e) => changeBlendingEfficiencyOne('base', +e.target.value)}
                  value={blendingEfficiencyOne.base ? `${blendingEfficiencyOne.base}` : ''}
                />
                <label className={css(styles.tableFieldText)}>mm²/s</label>
              </div>
            </div>
            {/* Row 2 */}
            <div className={css(styles.tableRow)}>
              <div className={css(styles.tableLabel)}>
                <p className={css(styles.tableLabelText)}>Blend</p>
              </div>
              <div className={css(styles.tableField)}>
                <input
                  className={css(styles.tableFieldInput)}
                  maxLength={10}
                  placeholder="Enter as 100°C"
                  onChange={(e) => changeBlendingEfficiencyOne('blend', +e.target.value)}
                  value={blendingEfficiencyOne.blend ? `${blendingEfficiencyOne.blend}` : ''}
                />
                <label className={css(styles.tableFieldText)}>mm²/s</label>
              </div>
            </div>
            {/* Row 3 */}
            <div className={css(styles.tableRow)}>
              <div className={css(styles.tableLabel)}>
                <p className={css(styles.tableLabelText)}>VII</p>
              </div>
              <div className={css(styles.tableField)}>
                <select
                  className={css(styles.select)}
                  value={blendingEfficiencyOne.vii}
                  onChange={(e) => changeBlendingEfficiencyOne('temp', +e.target.value)}
                >
                  {blendingEfficiencyVii.map(x => (
                    <option key={`select-1-${x.label}`} value={x.value}>{x.label}</option>
                  ))}
                </select>
              </div>
            </div>
            {/* Actions */}
            <div className={css(styles.tableActions)}>
              <button className={css(styles.tableAction, styles.tableActionLeft)} onClick={() => calculateBlendingEfficiencyOne()}>
                <span className={css(styles.tableActionText)}>CALCULATE</span>
              </button>
              <button className={css(styles.tableAction)} onClick={() => resetCalculator('blendingEfficiencyOne')}>
                <img className={css(styles.tableActionIcon)} src={Refresh} alt='refresh' />
                <span className={css(styles.tableActionText)}>RESET</span>
              </button>
            </div>
          </div>
          {/* Results & Errors */}
          {blendingEfficiencyOne.error &&
            <div className={css(styles.tableError)}>
              <p className={css(styles.tableErrorText)}>Error {blendingEfficiencyOne.error}</p>
            </div>
          }
          <div className={css(
            styles.tableResults,
            (blendingEfficiencyOne.resultVii !== undefined && blendingEfficiencyOne.resultBase !== undefined) ? styles.tableResultsActive : {},
          )}>
            <div className={css(styles.tableResultsLabel)}>
              <p className={css(styles.tableResultsText)}>RESULTS</p>
            </div>
            <div className={css(styles.tableResultsValues)}>
              <p className={css(styles.tableResultsText)}>
                VII: {blendingEfficiencyOne.resultVii && `${blendingEfficiencyOne.resultVii}%`}
              </p>
              <p className={css(styles.tableResultsText)}>
                Base Oil: {blendingEfficiencyOne.resultBase && `${blendingEfficiencyOne.resultBase}%`}
              </p>
            </div>
          </div>
        </div>

        {/* Form Two */}
        <div className={css(styles.tableContainer)}>
          <div className={css(styles.table)}>
            {/* Row 1 */}
            <div className={css(styles.tableRow)}>
              <div className={css(styles.tableLabel)}>
                <p className={css(styles.tableLabelText)}>Base Oil</p>
              </div>
              <div className={css(styles.tableField)}>
                <input
                  className={css(styles.tableFieldInput)}
                  maxLength={10}
                  placeholder=''
                  onChange={(e) => changeBlendingEfficiencyTwo('base', +e.target.value)}
                  value={blendingEfficiencyTwo.base ? `${blendingEfficiencyTwo.base}` : ''}
                />
                <label className={css(styles.tableFieldText)}>cSt</label>
              </div>
            </div>
            {/* Row 2 */}
            <div className={css(styles.tableRow)}>
              <div className={css(styles.tableLabel)}>
                <p className={css(styles.tableLabelText)}>VII</p>
              </div>
              <div className={css(styles.tableField)}>
                <input
                  className={css(styles.tableFieldInput)}
                  maxLength={10}
                  placeholder=''
                  onChange={(e) => changeBlendingEfficiencyTwo('percent', +e.target.value)}
                  value={blendingEfficiencyTwo.percent ? `${blendingEfficiencyTwo.percent}` : ''}
                />
                <label className={css(styles.tableFieldText)}>%</label>
              </div>
            </div>
            {/* Row 3 */}
            <div className={css(styles.tableRow)}>
              <div className={css(styles.tableLabel)}>
                <p className={css(styles.tableLabelText)}>VII</p>
              </div>
              <div className={css(styles.tableField)}>
                <select
                  className={css(styles.select)}
                  value={blendingEfficiencyTwo.vii}
                  onChange={(e) => changeBlendingEfficiencyTwo('temp', +e.target.value)}
                >
                  {blendingEfficiencyVii.map(x => (
                    <option key={`select-2-${x.label}`} value={x.value}>{x.label}</option>
                  ))}
                </select>
              </div>
            </div>
            {/* Actions */}
            <div className={css(styles.tableActions)}>
              <button className={css(styles.tableAction, styles.tableActionLeft)} onClick={() => calculateBlendingEfficiencyTwo()}>
                <span className={css(styles.tableActionText)}>CALCULATE</span>
              </button>
              <button className={css(styles.tableAction)} onClick={() => resetCalculator('blendingEfficiencyTwo')}>
                <img className={css(styles.tableActionIcon)} src={Refresh} alt='refresh' />
                <span className={css(styles.tableActionText)}>RESET</span>
              </button>
            </div>
          </div>
          {/* Results & Errors */}
          {blendingEfficiencyTwo.error &&
            <div className={css(styles.tableError)}>
              <p className={css(styles.tableErrorText)}>Error {blendingEfficiencyTwo.error}</p>
            </div>
          }
          <div className={css(
            styles.tableResults,
            blendingEfficiencyTwo.resultBlend ? styles.tableResultsActive : {},
          )}>
            <div className={css(styles.tableResultsLabel)}>
              <p className={css(styles.tableResultsText)}>RESULTS</p>
            </div>
            <div className={css(styles.tableResultsValues)}>
              <p className={css(styles.tableResultsText)}>
                Blend: {blendingEfficiencyTwo.resultBlend && `${blendingEfficiencyTwo.resultBlend} cSt`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(BlendingEfficiencyComponent);