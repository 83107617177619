import { css } from '@emotion/css';

export const styles = {
  pdfContainer: {},
  pdfView: css({
    marginTop: 60,
  }),
  videoContainer:{},
  videoView: css({
   marginTop: 60,
   position: 'relative',
   paddingBottom: '56.25%',
   height: 0
  }),
  videoViewVideo: css({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  }),
  loaderContainer: css({
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }),
  loader: css({
    display: 'inline-block',
    position: 'relative',
    width: 80,
    height: 80,
    '& div': {
      boxSizing: 'border-box',
      display: 'block',
      position: 'absolute',
      width: 64,
      height: 64,
      margin: 8,
      border: '8px solid black',
      borderRadius: '50%',
      animation: 'loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite',
      borderColor: 'black transparent transparent transparent'
    },
    '& div:nth-child(1)': {
      animationDelay: '-0.45s'
    },
    '& div:nth-child(2)': {
      animationDelay: '-0.3s'
    },
    '& div:nth-child(3)': {
      animationDelay: '-0.15s'
    },
    '& div:nth-child(4)': {
      animationDelay: '-0.15s'
    }
  })
};
