import { css } from '@emotion/css';
import { appVariables } from '../../config/variables';

export const styles = {
  container: css({
    paddingLeft: 80,
    paddingRight: 80,
  }),
  tables: css({
    display: 'flex',
    flexWrap: 'wrap'
  }),
  tablesTop: css({
    marginTop: 30
  }),
  tablesBottom: css({
    marginBottom: 30
  }),
  description: css({
    width: 500,
    marginTop: 30,
    marginBottom: 20,
    fontSize: 18,
    fontFamily: appVariables.font.family
  }),
  tableContainer: css({
    width: '48%',
    '&:nth-child(even)': {
      marginLeft: '2%'
    }
  }),
  tableTitle: css({
    fontSize: 18,
    fontFamily: appVariables.font.familyBold,
    marginBottom: 10
  }),
  table: css({
    borderTop: `2px solid ${appVariables.colors.lightGray}`,
    borderLeft: `2px solid ${appVariables.colors.lightGray}`,
    borderRight: `2px solid ${appVariables.colors.lightGray}`
  }),
  tableRow: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    borderBottom: `2px solid ${appVariables.colors.lightGray}`
  }),
  tableLabel: css({
    maxWidth: '30%',
    minWidth: '30%',
    width: '30%',
    padding: '20px 25px',
    borderRight: `2px solid ${appVariables.colors.lightGray}`,
    color: '#333333'
  }),
  tableLabelText: css({
    fontSize: 16,
    fontFamily: appVariables.font.family,
    color: '#333333'
  }),
  tableField: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
    position: 'relative',
    width: '100%'
  }),
  tableFieldInput: css({
    padding: 15,
    width: '73%',
    backgroundColor: '#EAEAEA',
    color: '#333333',
    fontSize: 14,
    fontFamily: appVariables.font.family
  }),
  tableFieldText: css({
    width: '23%',
    fontSize: 16,
    textAlign: 'center'
  }),
  tableActions: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    borderBottom: `2px solid ${appVariables.colors.lightGray}`,
  }),
  tableAction: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: appVariables.colors.purple,
    width: '30%',
    paddingTop: 15,
    paddingBottom: 15,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'black'
    }
  }),
  tableActionLeft: css({
    marginRight: 15
  }),
  tableActionText: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: 16,
    fontFamily: appVariables.font.family,
  }),
  tableActionIcon: css({
    width: 20,
    height: 20,
    marginRight: 10
  }),
  tableResults: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
    border: `2px solid ${appVariables.colors.purple}`
  }),
  tableResultsLabel: css({
    width: '28%',
    padding: '20px 25px',
    borderRight: `2px solid ${appVariables.colors.purple}`,
    color: appVariables.colors.purple,
  }),
  tableResultsValues: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 20,
    paddingRight: 20
  }),
  tableResultsValueRight: css({
    marginLeft: 20,
  }),
  tableResultsText: css({
    color: appVariables.colors.purple,
    fontFamily: appVariables.font.familyBold,
    fontSize: 16
  }),
  tableResultsActive: css({
    backgroundColor: appVariables.colors.lightPurple
  }),
  tableError: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 25,
    padding: 25,
    backgroundColor: appVariables.colors.lightRed
  }),
  tableErrorText: css({
    fontFamily: appVariables.font.familyBold,
    fontSize: 16,
    color: appVariables.colors.red
  }),
  select: css({
    display: 'block',
    padding: 15,
    width: '73%',
    backgroundColor: '#EAEAEA',
    color: '#333333',
    fontSize: 15,
    fontFamily: appVariables.font.family,
    cursor: 'pointer',
  })
}
