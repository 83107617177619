export const appVariables = {
  colors: {
    purple: '#932c91',
    lightPurple: '#E8D1E8',
    darkGray: '#929497',
    mediumGray: '#dad5c9',
    lightGray: '#d2d3d4',
    lighterGray: '#a19f9a',
    lightestGray: '#ababab',
    red: '#AE0909',
    lightRed: 'rgba(174, 9, 9, 0.1)'
  },
  font: {
    family: 'evonikRegular',
    familyBold: 'evonikBold',
    letterSpacing: 1
  }
};
