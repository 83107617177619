import { appStore } from '../../../store';
import { Session } from '../../models';

export function storeSession(request?: Session) {
  if (!request) {
    return;
  }

  appStore.sessionStore.updateActiveSession(request);
}
