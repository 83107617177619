import { BaseOilMixturesOne, BaseOilMixturesTwo } from '../models/baseOilMixtures';
import { BlendingEfficiencyOne, BlendingEfficiencyTwo } from '../models/blendingEfficiency';
import { ViscosityIndex } from '../models/viscosityIndex';

export const blendingEfficiencyVii = [
  { label: 'VISCOPLEX® 0-030', value: '0-0.0413' },
  { label: 'VISCOPLEX® 0-050', value: '0-0.0554' },
  { label: 'VISCOPLEX® 0-101', value: '0-0.0647' },
  { label: 'VISCOPLEX® 0-110', value: '0-0.0655' },
  { label: 'VISCOPLEX® 0-111', value: '0-0.0509' },
  { label: 'VISCOPLEX® 0-112', value: '0-0.0498' },
  { label: 'VISCOPLEX® 0-113', value: '0-0.0508' },
  { label: 'VISCOPLEX® 0-120', value: '0-0.0601' },
  { label: 'VISCOPLEX® 0-212', value: '0-0.0766' },
  { label: 'VISCOPLEX® 0-219', value: '0-0.0735' },
  { label: 'VISCOPLEX® 0-220', value: '0-0.0734' },
  { label: 'VISCOPLEX® 0-223', value: '0-0.0790' },
  { label: 'VISCOPLEX® 0-400', value: '0-0.0888' },
  { label: 'VISCOPLEX® 10-930', value: '10-0.1245' },
  { label: 'VISCOPLEX® 2-360', value: '2-0.1449' },
  { label: 'VISCOPLEX® 2-540', value: '2-0.1592' },
  { label: 'VISCOPLEX® 2-602', value: '2-0.1820' },
  { label: 'VISCOPLEX® 3-500', value: '3-0.1274' },
  { label: 'VISCOPLEX® 3-700', value: '3-0.1515' },
  { label: 'VISCOPLEX® 4-677', value: '4-0.3313' },
  { label: 'VISCOPLEX® 6-054', value: '6-0.0850' },
  { label: 'VISCOPLEX® 6-565', value: '6-0.1362' },
  { label: 'VISCOPLEX® 6-954', value: '6-0.1459' },
  { label: 'VISCOPLEX® 7-300', value: '7-0.0913' },
  { label: 'VISCOPLEX® 7-305', value: '7-0.0990' },
  { label: 'VISCOPLEX® 7-310', value: '7-0.0814' },
  { label: 'VISCOPLEX® 7-711', value: '7-0.1206' },
  { label: 'VISCOPLEX® 8-100', value: '8-0.0647' },
  { label: 'VISCOPLEX® 8-200', value: '8-0.0762' },
  { label: 'VISCOPLEX® 8-219', value: '8-0.0705' },
  { label: 'VISCOPLEX® 8-220', value: '8-0.0734' },
  { label: 'VISCOPLEX® 8-226', value: '8-0.0789' },
  { label: 'VISCOPLEX® 8-251', value: '8-0.0949' },
  { label: 'VISCOPLEX® 8-280', value: '8-0.0830' },
  { label: 'VISCOPLEX® 8-300', value: '8-0.0971' },
  { label: 'VISCOPLEX® 8-310', value: '8-0.0958' },
  { label: 'VISCOPLEX® 8-350', value: '8-0.0983' },
  { label: 'VISCOPLEX® 8-400', value: '8-0.1032' },
  { label: 'VISCOPLEX® 8-407', value: '8-0.1094' },
  { label: 'VISCOPLEX® 8-440', value: '8-0.1163' },
  { label: 'VISCOPLEX® 8-702', value: '8-0.1580' },
  { label: 'VISCOPLEX® 8-705', value: '8-0.1515' },
  { label: 'VISCOPLEX® 8-800', value: '8-0.1475' },
];

export const calculateBlendingEfficiencyOneApi = (data: BlendingEfficiencyOne) => {
  if (data.base && data.blend && data.vii) {
    const { base, blend } = data;
    const vii = Number(data.vii.slice(data.vii.indexOf('-') + 1));

    if (vii >= 0.3313) {
      const resultVii = Math.round(10*((1-Math.sqrt(1-0.48*Math.log(blend/base)))/(0.24*vii*(1-0.0263*base))))/10;
      return {
        resultVii,
        resultBase: 100 - resultVii,
      }
    }

    const resultVii = Math.round(10*((1-Math.sqrt(1-0.48*Math.log(blend/base)))/(0.24*vii*(1-0.0263*base))))/10;
    return {
      resultVii,
      resultBase: 100 - resultVii,
    }
  }
}

export const calculateBlendingEfficiencyTwoApi = (data: BlendingEfficiencyTwo) => {
  if (data.base && data.percent) {
    const { base, percent } = data;
    const vii = Number(data.vii.slice(data.vii.indexOf('-') + 1));

    if (vii >= 0.3313) {
      return {
        resultBlend: Math.round(100*(base*Math.exp(percent*vii*(1-0.0181*base)-0.0917*Math.pow(percent*vii*(1-0.0181*base),2))))/100
      }
    }

    return {
      resultBlend: Math.round(100*(base*Math.exp(percent*vii*(1-0.0263*base)-0.12*Math.pow(percent*vii*(1-0.0263*base),2))))/100,
    }
  }
}

export const calculateViscosityIndexViApi = (data: ViscosityIndex) => {
  if (data.kv40 && data.kv100) {
    const kv40 = parseFloat(`${data.kv40}`);
    const kv100 = parseFloat(`${data.kv100}`);
    let q1;
    let q2;
    let q3;
    let q4;
    let q5;

    if (kv100 >= 2 && kv100 < 4) {
      q1 = 0.827 * Math.pow(kv100, 2) + 1.632 * kv100 - 0.181;
      q2 = 0.3094 * Math.pow(kv100, 2) + 0.182 * kv100;
      q4 = (q1 + q2 - kv40) / q2 * 100;
    }

    if (kv100 >= 4 && kv100 < 6.1) {
      q1 = -2.6758 * Math.pow(kv100, 2) + 96.671 * kv100 - 269.664 * Math.sqrt(kv100) + 215.025;
      q2 = -7.1955 * Math.pow(kv100, 2) + 241.992 * kv100 - 725.478 * Math.sqrt(kv100) + 603.88;
      q4 = (q1 + q2 - kv40) / q2 * 100;           
    }

    if (kv100 >= 6.1 && kv100 < 7.2) {
      q1 = 2.32 * Math.pow(kv100, 1.5626);
      q2 = 2.838 * Math.pow(kv100, 2) - 27.35 * kv100 + 81.83;
      q4 = (q1 + q2 - kv40) / q2 * 100;
    }

    if (kv100 >= 7.2 && kv100 < 12.4) {
      q1 = 0.1922 * Math.pow(kv100, 2) + 8.25 * kv100 - 18.728;
      q2 = 0.5463 * Math.pow(kv100, 2) + 2.442 * kv100 - 14.16;
      q4 = (q1 + q2 - kv40) / q2 * 100;
    } 

    if (kv100 >= 12.4 && kv100 <= 70) {
      q1 = 1795.2 * Math.pow(kv100, (-2)) + 0.1818 * Math.pow(kv100, 2) + 10.357 * kv100 - 54.547;
      q2 = 0.6995 * Math.pow(kv100, 2) - 1.19 * kv100 + 7.6;
      q4 = (q1 + q1 - kv40) / q2 * 100;
    } 

    if (kv100 > 70){
      q1 = 0.1684 * Math.pow(kv100, 2) + 11.85 * kv100 -97;
      q2 = 0.6669 * Math.pow(kv100, 2) + 2.82 * kv100 -119;
      q3 = 0.8353 * Math.pow(kv100, 2) + 14.67 * kv100 -216;
      q4 =(q3 - kv40) / q2 * 100;
    }

    if (q1 && q4 && q4 >= 100) {
      q5 = ((Math.log(q1) / Math.log(10)) - (Math.log(kv40) / Math.log(10))) / ( Math.log(kv100)/ Math.log(10)); 
      q4 = (( Math.pow(10, q5)-1) / 0.00715 ) + 100; 
    }

    return {
      viscosityIndex: q4 ? parseInt(`${q4 + 0.5}`) : undefined
    }; 
  }
}

export const calculateViscosityIndex100Api = (data: ViscosityIndex) => {
  if (data.viscosityIndex && data.kv40) {
    const viscosityIndex = parseFloat(`${data.viscosityIndex}`);
    const kv40 = parseFloat(`${data.kv40}`);
    let vi;
    let n = 2;

    do {
      vi = calculateViscosityIndexViApi({ kv40, kv100: n }); 
      n = n + 0.01;
    } while (vi && vi.viscosityIndex && vi.viscosityIndex <= viscosityIndex && n <= 500.00);

    return {
      kv100: parseInt(`${n * 100 + 0.01}`) / 100
    };
  }
}

export const calculateViscosityIndex40Api = (data: ViscosityIndex) => {
  if (data.viscosityIndex && data.kv100) {
    const viscosityIndex = parseFloat(`${data.viscosityIndex}`);
    const kv100 = parseFloat(`${data.kv100}`);
    let vi;
    let n = kv100;

    do {
      vi = calculateViscosityIndexViApi({ kv40: n, kv100 }); 
      n = n + 0.05;
    } while ( vi && vi.viscosityIndex && vi.viscosityIndex >= viscosityIndex && n <= 2000);

    return {
      kv40: parseInt(`${n * 100 + 0.1}`) / 100
    };
  }
}

/** Base Oil Mixtures */
export const baseOilMixturesTemp = [
  { label: '100', value: 1.8 },
  { label: '40', value: 4.1 },
  { label: '-5', value: 1.9 },
];

export const calculateBaseOilMixturesOneApi = (data: BaseOilMixturesOne) => {
  const kv1 = Number(data.kv1);
  const kv2 = Number(data.kv2);
  const temp = Number(data.temp);
  const oil1 = Number(data.oil1) / 100;

  const resultFinalKv = Math.round(100*(Math.exp(Math.log(kv2+temp)*Math.exp(oil1*Math.log(Math.log(kv1+temp)/Math.log(kv2+temp))))-temp))/100;
  
  return {
    resultFinalKv
  }
}

export const calculateBaseOilMixturesTwoApi = (data: BaseOilMixturesTwo) => {
  const kv1 = Number(data.kv1);
  const kv2 = Number(data.kv2);
  const finalKv = Number(data.finalKv);
  const temp = Number(data.temp);

  const a = Math.log(finalKv+temp);
  const b = Math.log(kv1+temp);
  const c = Math.log(kv2+temp);

  const resultOil1 = Math.round(10000*(Math.log(a/c)/Math.log(b/c)))/100;
  const resultOil2 = 100 - resultOil1;
  
  return {
    resultOil1,
    resultOil2,
  }
}
