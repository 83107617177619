import Literature from '../assets/files/icons/literature.svg';
import Calculators from '../assets/files/icons/calculators.svg';
import Videos from '../assets/files/icons/videos.svg';
import Presentations from '../assets/files/icons/presentations.svg';
import Website from '../assets/files/icons/website.svg';
import CloseGray from '../assets/files/icons/close--gray.svg';
import Close from '../assets/files/icons/close.svg';
import Connect from '../assets/files/icons/connect.svg';
import EmailGray from '../assets/files/icons/email--gray.svg';
import Email from '../assets/files/icons/email.svg';
import LogoPurple from '../assets/files/icons/logo--purple.svg';
import Logo from '../assets/files/icons/logo.svg';
import NavPurple from '../assets/files/icons/nav--purple.svg';
import Nav from '../assets/files/icons/nav.svg';
import Refresh from '../assets/files/icons/refresh.svg';

export default {
'calculators': Calculators,
'close--gray': CloseGray,
'close': Close,
'connect': Connect,
'email--gray': EmailGray,
'email': Email,
'literature': Literature,
'logo--purple': LogoPurple,
'logo': Logo,
'nav--purple': NavPurple,
'nav': Nav,
'presentations': Presentations,
'refresh': Refresh,
'videos': Videos,
'website': Website,
'base-oil-mixtures': '/assets/files/thumbnails/base-oil-mixtures.jpg',
'blending-efficiency': '/assets/files/thumbnails/blending-efficiency.jpg',
'copi-technology-crude-oil-paraffin-inhibitors': '/assets/files/thumbnails/copi-technology-crude-oil-paraffin-inhibitors.jpg',
'demonstrating-system-efficiency-of-construction-equipment': '/assets/files/thumbnails/demonstrating-system-efficiency-of-construction-equipment.jpg',
'drivon-technology-fuel-efficient-powertrain-solutions': '/assets/files/thumbnails/drivon-technology-fuel-efficient-powertrain-solutions.jpg',
'dynavis-technology-for-injection-molding': '/assets/files/thumbnails/dynavis-technology-for-injection-molding.jpg',
'dynavis-technology-resource-efficient-hydraulic-additives': '/assets/files/thumbnails/dynavis-technology-resource-efficient-hydraulic-additives.jpg',
'efficiency-gains-in-construction-manufacturing-and-mining-have-become-reality-with-dynavis-technology': '/assets/files/thumbnails/efficiency-gains-in-construction-manufacturing-and-mining-have-become-reality-with-dynavis-technology.jpg',
'evoniks-viscoplex-additives-and-viscobase-base-oils': '/assets/files/thumbnails/evoniks-viscoplex-additives-and-viscobase-base-oils.jpg',
'hydraulic-fluids-a-treatise-on-formula-for-performance-and-efficiency': '/assets/files/thumbnails/hydraulic-fluids-a-treatise-on-formula-for-performance-and-efficiency.jpg',
'impact-of-vi-improver-on-the-formation-of-piston-deposits-in-fuel-efficient-engine-oils': '/assets/files/thumbnails/impact-of-vi-improver-on-the-formation-of-piston-deposits-in-fuel-efficient-engine-oils.jpg',
'nuflux-technology': '/assets/files/thumbnails/nuflux-technology.jpg',
'oil-additives-product-application-guide': '/assets/files/thumbnails/oil-additives-product-application-guide.jpg',
'ppd-considerations-for-sae-0w-16-formulations': '/assets/files/thumbnails/ppd-considerations-for-sae-0w-16-formulations.jpg',
'robo-as-a-formulation-tool-for-engine-oil-development': '/assets/files/thumbnails/robo-as-a-formulation-tool-for-engine-oil-development.jpg',
'the-benefits-of-novel-functionalized-viscosity-index-improvers-in-wet-clutch-systems': '/assets/files/thumbnails/the-benefits-of-novel-functionalized-viscosity-index-improvers-in-wet-clutch-systems.jpg',
'viscobase-synthetic-base-fluids': '/assets/files/thumbnails/viscobase-synthetic-base-fluids.jpg',
'viscoplex-additives-for-hydraulic-fluids-used-in-manufacturing-equipment': '/assets/files/thumbnails/viscoplex-additives-for-hydraulic-fluids-used-in-manufacturing-equipment.jpg',
'viscoplex-additives-for-mobile-hydraulic-equipment': '/assets/files/thumbnails/viscoplex-additives-for-mobile-hydraulic-equipment.jpg',
'viscoplex-cold-flow-improvers': '/assets/files/thumbnails/viscoplex-cold-flow-improvers.jpg',
'viscoplex-pour-point-depressant-technologies': '/assets/files/thumbnails/viscoplex-pour-point-depressant-technologies.jpg',
'viscoplex-vii-shock-and-steering': '/assets/files/thumbnails/viscoplex-vii-shock-and-steering.jpg',
'viscosity-index': '/assets/files/thumbnails/viscosity-index.jpg', 'viscoplex-additives-for-hydraulic-and-utto-fluids-in-agriculture-and-forestry': '/assets/files/thumbnails/viscoplex-additives-for-hydraulic-and-utto-fluids-in-agriculture-and-forestry.jpg',
'drivon-the-ultimate-test': '/assets/files/thumbnails/drivon-the-ultimate-test.jpg',
'dynavis-performance-demonstration-siberia-russia': '/assets/files/thumbnails/dynavis-performance-demonstration-siberia-russia.jpg',
'image-of-innovation': '/assets/files/thumbnails/image-of-innovation.jpg',
'viscoplex-crude-oil-paraffin-inhibitors': '/assets/files/thumbnails/viscoplex-crude-oil-paraffin-inhibitors.jpg',
'viscoplex-ppd-experience': '/assets/files/thumbnails/viscoplex-ppd-experience.jpg',
'viscoplex-ppds-in-action': '/assets/files/thumbnails/viscoplex-ppds-in-action.jpg',} as { [key: string]: any };
