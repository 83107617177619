import React from 'react';
import { observer } from 'mobx-react-lite';
import { css } from '@emotion/css';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';

import ActionBarComponent from '../actionBar/actionBar.component';
import { ActionBarType } from '../actionBar/enums/actionBarType';
import Pdfs from '../../config/pdfs';

import { styles } from './resource.styles';
import { IAppStore } from '../../core/models';
import { useParams } from 'react-router-dom';
import { data } from '../../assets/data/data';
import { Child, Page } from '../../models';

const ResourceContainer= ({ store, ui }: {
  store: IAppStore;
  ui: { page: keyof Page; };
}) => {
  const { id } = useParams<{ id: string; }>();
  const resourceData = data.pages[ui.page].children![id as keyof Child];

  return (
    <div className={css(styles.pdfContainer)}>
      <ActionBarComponent
        ui={{
          type: ActionBarType.PDF,
          displayEmailAction: !!data.pages[ui.page].emailable && !store.sessionStore.ui.turnOffEmailing,
          page: ui.page,
          title: resourceData.title,
          link: resourceData.link || ''
        }}
        store={store.uiStore}
      />

      <div className={css(styles.pdfView)}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
          <Viewer
            fileUrl={Pdfs[resourceData.id]}
            renderLoader={() => (
              <div className={css(styles.loaderContainer)}>
                <div className={css(styles.loader)}><div></div><div></div><div></div><div></div></div>
              </div>
          )}/>
        </Worker>
      </div>
    </div>
  );
}

export default observer(ResourceContainer);

