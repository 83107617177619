import React from 'react';
import { css } from '@emotion/css';

import { styles } from './pager.styles';

const PagerDotsComponent = ({ ui }: { ui: {
  setCurrent: (idx: number) => void;
  chunks: string[][];
  page: string;
  currentIndex: number;
}}) => {
  return (
    <div className={css(styles.pagerDots)}>
      {ui.chunks.map((_, i) => (
        <button
          onClick={() => ui.setCurrent(i)}
          key={`pager-dot-${i}`}
          className={css(
            styles.pagerDot,
            ui.currentIndex === i ? styles.pagerDotActive : {}
          )}
        />
      ))}
    </div>
  );
};


export default PagerDotsComponent;