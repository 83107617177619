import React from 'react';
import { observer } from 'mobx-react-lite';
import { css } from '@emotion/css';

import Videos from '../../../config/videos';
import { IAppStore } from '../../../core/models';
import { styles } from '../entry.styles';


const EntryContainer = ({ store }: { store: IAppStore }) => {
  return (
    <video autoPlay muted loop className={css(styles.video)}>
      <source src={Videos['entry']} type='video/mp4' />
    </video>
  );
}

export default observer(EntryContainer);