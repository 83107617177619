
export const maskEmail = (email: string) => {
  const censor = (str: string) => str[0] + '*'.repeat(str.length - 2) + str.slice(-4);

  const parts = email.split('@');
  return censor(parts[0]) + '@' + censor(parts[1]);
}

export const validateEmail = (email: string) => {
  var regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
}
