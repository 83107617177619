import { reaction } from 'mobx';

import { routeChanged } from './routeChanged';
import { appStore } from '../../../store';

reaction(
  () => appStore.routerStore.location,
  routeChanged,
  {
    delay: 1 // added delay so stores are updated - known Mobx/react route change issue
  }
);