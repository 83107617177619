import React from 'react';
import { css } from '@emotion/css';

import PagerItem from './pagerItem.component';
import { styles } from './pager.styles';

const PagerGroupComponent = ({ ui }: { ui: {
  chunk: string[];
  page: string;
  children: any;
  itemPath: string;
}}) => {
  return (
    <div className={css(styles.pagerGroup)}>
      {ui.chunk.map((key: string, i: number) => (
        <PagerItem
          key={`pager-item-${key}`}
          ui={{
            item: ui.children[key],
            page: ui.page,
            itemPath: ui.itemPath
          }}
        />
      ))}
    </div>
  );
};

export default PagerGroupComponent;

