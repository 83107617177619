import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/css';

import Images from '../../config/images';
import { styles } from './pager.styles';
import { appStore } from '../../store';

const PagerItemComponent = ({ ui }: { ui: {
  item: any,
  page: string;
  itemPath: string;
}}) => {
  return (
    <Link
      className={css(styles.pagerItem)}
      onClick={() => appStore.uiStore.updateModals({ current: ui.item.id })}
      to={`/${ui.page}/${ui.itemPath}/${ui.item.id}`}
    >
      <div className={css(styles.pagerThumbnail)} style={{ backgroundImage: `url(${Images[ui.item.id]})` }}></div>
      <div className={css(styles.pagerTextContainer)}>
        <span>{ui.item.title}</span>
      </div>
    </Link>
  );
};

export default PagerItemComponent;