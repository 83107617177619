import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { css } from '@emotion/css';

import { calculateViscosityIndex100Api, calculateViscosityIndex40Api, calculateViscosityIndexViApi } from '../api/calculators';
import { ViscosityIndex } from '../models/viscosityIndex';
import { styles } from '../calculators.styles';
import Refresh from '../../../assets/files/icons/refresh.svg';

const initialStates: {
  viscosityIndexVi: ViscosityIndex;
  viscosityIndex100: ViscosityIndex;
  viscosityIndex40: ViscosityIndex;
} = {
  viscosityIndexVi: {
    kv40: undefined,
    kv100: undefined,
    viscosityIndex: undefined,
    error: '',
  },
  viscosityIndex100: {
    viscosityIndex: undefined,
    kv40: undefined,
    kv100: undefined,
    error: '',
  },
  viscosityIndex40: {
    viscosityIndex: undefined,
    kv40: undefined,
    kv100: undefined,
    error: '',
  }
};

const ViscosityIndexComponent = ({ data }: { data: any }) => {
  const [viscosityIndexVi, setViscosityIndexVi] = useState(initialStates.viscosityIndexVi);
  const [viscosityIndex100, setViscosityIndex100] = useState(initialStates.viscosityIndex100);
  const [viscosityIndex40, setViscosityIndex40] = useState(initialStates.viscosityIndex40);

  function changeViscosityIndexVi(field: string, value: number) {
    setViscosityIndexVi({
      ...viscosityIndexVi,
      [field]: value
    });
  }
  
  function changeViscosityIndex100(field: string, value: number) {
    setViscosityIndex100({
      ...viscosityIndex100,
      [field]: value
    });
  }

  function changeViscosityIndex40(field: string, value: number) {
    setViscosityIndex40({
      ...viscosityIndex40,
      [field]: value
    });
  }

  function resetCalculator(calc: 'viscosityIndexVi' | 'viscosityIndex100' | 'viscosityIndex40') {
    if (calc === 'viscosityIndexVi') {
      setViscosityIndexVi(initialStates.viscosityIndexVi);
    } else if (calc === 'viscosityIndex100') {
      setViscosityIndex100(initialStates.viscosityIndex100);
    } else {
      setViscosityIndex40(initialStates.viscosityIndex40);
    }
  };

  function calculateViscosityIndexVi() {
    if (!viscosityIndexVi.kv40) {
      setViscosityIndexVi({
        ...viscosityIndexVi,
        error: 'Please enter a number as KV40!',
      });
      return;
    }

    if (Number(viscosityIndexVi.kv40) < 2) {
      setViscosityIndexVi({
        ...viscosityIndexVi,
        error: 'Please enter a KV40 higher than 2!',
      });
      return;
    }

    if (!viscosityIndexVi.kv100) {
      setViscosityIndexVi({
        ...viscosityIndexVi,
        error: 'Please enter a number as KV100!',
      });
      return;
    }

    if (Number(viscosityIndexVi.kv100) < 2) {
      setViscosityIndexVi({
        ...viscosityIndexVi,
        error: 'Please enter a KV100 higher than 2!',
      });
      return;
    }

    if (Number(viscosityIndexVi.kv40) <= Number(viscosityIndexVi.kv100)) {
      setViscosityIndexVi({
        ...viscosityIndexVi,
        error: 'KV40 must be superior than KV100!',
      });
      return;
    }
    
    const result = calculateViscosityIndexViApi(viscosityIndexVi);
    if (!result) {
      setViscosityIndexVi({
        ...viscosityIndexVi,
        error: 'Something went wrong.',
      });
      return;
    }

    setViscosityIndexVi({
      ...viscosityIndexVi,
      error: '',
      viscosityIndex: result.viscosityIndex
    });
  }

  function calculateViscosityIndex100() {
    if (!viscosityIndex100.viscosityIndex) {
      setViscosityIndex100({
        ...viscosityIndex100,
        error: 'Please enter a number as Viscosity Index!',
      });
      return;
    }

    if (!viscosityIndex100.kv40) {
      setViscosityIndex100({
        ...viscosityIndex100,
        error: 'Please enter a number as KV40!',
      });
      return;
    }

    if (Number(viscosityIndex100.kv40) < 2) {
      setViscosityIndex100({
        ...viscosityIndex100,
        error: 'Please enter a KV40 higher than 2!',
      });
      return;
    }

    const result = calculateViscosityIndex100Api(viscosityIndex100);
    if (!result) {
      setViscosityIndex100({
        ...viscosityIndex100,
        error: 'Something went wrong.',
      });
      return;
    }

    setViscosityIndex100({
      ...viscosityIndex100,
      error: '',
      kv100: result.kv100
    });
  }

  function calculateViscosityIndex40() {
    if (!viscosityIndex40.viscosityIndex) {
      setViscosityIndex40({
        ...viscosityIndex40,
        error: 'Please enter a number as Viscosity Index!'
      });
      return;
    }

    if (!viscosityIndex40.kv100) {
      setViscosityIndex40({
        ...viscosityIndex40,
        error: 'Please enter a number as KV100!'
      });
      return;
    }

    if (Number(viscosityIndex40.kv100) < 2) {
      setViscosityIndex40({
        ...viscosityIndex40,
        error: 'Please enter a KV100 higher than 2!'
      });
      return;
    }

    const result = calculateViscosityIndex40Api(viscosityIndex40);
    if (!result) {
      setViscosityIndex40({
        ...viscosityIndex40,
        error: 'Something went wrong.'
      });
      return;
    }
    
    setViscosityIndex40({
      ...viscosityIndex40,
      error: '',
      kv40: result.kv40
    });
  }

  return (
    <div className={css(styles.container)}>
      <div className={css(styles.tables, styles.tablesTop)}>
        <div className={css(styles.tableContainer)}>
          <h2 className={css(styles.tableTitle)}>{data.table1Title}</h2>
          <div className={css(styles.table)}>
            {/* Row 1 */}
            <div className={css(styles.tableRow)}>
              <div className={css(styles.tableLabel)}>
                <p className={css(styles.tableLabelText)}>KV40°C cSt</p>
              </div>
              <div className={css(styles.tableField)}>
                <input
                  className={css(styles.tableFieldInput)}
                  maxLength={10}
                  placeholder=''
                  onChange={(e) => changeViscosityIndexVi('kv40', +e.target.value)}
                  value={viscosityIndexVi.kv40 ? `${viscosityIndexVi.kv40}` : ''}
                />
                <label className={css(styles.tableFieldText)}>cSt</label>
              </div>
            </div>
            {/* Row 2 */}
            <div className={css(styles.tableRow)}>
              <div className={css(styles.tableLabel)}>
                <p className={css(styles.tableLabelText)}>KV100°C cSt</p>
              </div>
              <div className={css(styles.tableField)}>
                <input
                  className={css(styles.tableFieldInput)}
                  maxLength={10}
                  placeholder=''
                  onChange={(e) => changeViscosityIndexVi('kv100', +e.target.value)}
                  value={viscosityIndexVi.kv100 ? `${viscosityIndexVi.kv100}` : ''}
                />
                <label className={css(styles.tableFieldText)}>cSt</label>
              </div>
            </div>
            {/* Actions */}
            <div className={css(styles.tableActions)}>
              <button className={css(styles.tableAction, styles.tableActionLeft)} onClick={() => calculateViscosityIndexVi()}>
                <span className={css(styles.tableActionText)}>CALCULATE</span>
              </button>
              <button className={css(styles.tableAction)} onClick={() => resetCalculator('viscosityIndexVi')}>
                <img className={css(styles.tableActionIcon)} src={Refresh} alt='refresh' />
                <span className={css(styles.tableActionText)}>RESET</span>
              </button>
            </div>
          </div>
          {/* Results & Errors */}
          {viscosityIndexVi.error  &&
            <div className={css(styles.tableError)}>
              <p className={css(styles.tableErrorText)}>Error {viscosityIndexVi.error }</p>
            </div>
          }
          <div className={css(
            styles.tableResults,
            viscosityIndexVi.viscosityIndex ? styles.tableResultsActive : {},
          )}>
            <div className={css(styles.tableResultsLabel)}>
              <p className={css(styles.tableResultsText)}>RESULTS</p>
            </div>
            <div className={css(styles.tableResultsValues)}>
              <p className={css(styles.tableResultsText)}>
              Viscosity Index: {viscosityIndexVi.viscosityIndex && `${viscosityIndexVi.viscosityIndex} cSt`}
              </p>
            </div>
          </div>
        </div>

        <div className={css(styles.tableContainer)}>
          <h2 className={css(styles.tableTitle)}>{data.table2Title}</h2>
          <div className={css(styles.table)}>
            {/* Row 1 */}
            <div className={css(styles.tableRow)}>
              <div className={css(styles.tableLabel)}>
                <p className={css(styles.tableLabelText)}>Viscosity Index</p>
              </div>
              <div className={css(styles.tableField)}>
                <input
                  className={css(styles.tableFieldInput)}
                  maxLength={10}
                  placeholder=''
                  onChange={(e) => changeViscosityIndex100('viscosityIndex', +e.target.value)}
                  value={viscosityIndex100.viscosityIndex ? `${viscosityIndex100.viscosityIndex}` : ''}
                />
              </div>
            </div>
            {/* Row 2 */}
            <div className={css(styles.tableRow)}>
              <div className={css(styles.tableLabel)}>
                <p className={css(styles.tableLabelText)}>KV40°C</p>
              </div>
              <div className={css(styles.tableField)}>
                <input
                  className={css(styles.tableFieldInput)}
                  maxLength={10}
                  placeholder=''
                  onChange={(e) => changeViscosityIndex100('kv40', +e.target.value)}
                  value={viscosityIndex100.kv40 ? `${viscosityIndex100.kv40}` : ''}
                />
                <label className={css(styles.tableFieldText)}>cSt</label>
              </div>
            </div>
            {/* Actions */}
            <div className={css(styles.tableActions)}>
              <button className={css(styles.tableAction, styles.tableActionLeft)} onClick={() => calculateViscosityIndex100()}>
                <span className={css(styles.tableActionText)}>CALCULATE</span>
              </button>
              <button className={css(styles.tableAction)} onClick={() => resetCalculator('viscosityIndex100')}>
                <img className={css(styles.tableActionIcon)} src={Refresh} alt='refresh' />
                <span className={css(styles.tableActionText)}>RESET</span>
              </button>
            </div>
          </div>
          {/* Results & Errors */}
          {viscosityIndex100.error  &&
            <div className={css(styles.tableError)}>
              <p className={css(styles.tableErrorText)}>Error {viscosityIndex100.error }</p>
            </div>
          }
          <div className={css(
            styles.tableResults,
            viscosityIndex100.kv100 ? styles.tableResultsActive : {},
          )}>
            <div className={css(styles.tableResultsLabel)}>
              <p className={css(styles.tableResultsText)}>RESULTS</p>
            </div>
            <div className={css(styles.tableResultsValues)}>
              <p className={css(styles.tableResultsText)}>
                KV100 °C: {viscosityIndex100.kv100 && `${viscosityIndex100.kv100} cSt`}
              </p>
            </div> 
          </div>
        </div>
      </div>
      <div className={css(styles.tables, styles.tablesTop, styles.tablesBottom)}>
        <div className={css(styles.tableContainer)}>
          <h2 className={css(styles.tableTitle)}>{data.table3Title}</h2>
          <div className={css(styles.table)}>
            {/* Row 1 */}
            <div className={css(styles.tableRow)}>
              <div className={css(styles.tableLabel)}>
                <p className={css(styles.tableLabelText)}>Viscosity Index</p>
              </div>
              <div className={css(styles.tableField)}>
                <input
                  className={css(styles.tableFieldInput)}
                  maxLength={10}
                  placeholder=''
                  onChange={(e) => changeViscosityIndex40('viscosityIndex', +e.target.value)}
                  value={viscosityIndex40.viscosityIndex ? `${viscosityIndex40.viscosityIndex}` : ''}
                />
              </div>
            </div>
            {/* Row 2 */}
            <div className={css(styles.tableRow)}>
              <div className={css(styles.tableLabel)}>
                <p className={css(styles.tableLabelText)}>KV100°C</p>
              </div>
              <div className={css(styles.tableField)}>
                <input
                  className={css(styles.tableFieldInput)}
                  maxLength={10}
                  placeholder=''
                  onChange={(e) => changeViscosityIndex40('kv100', +e.target.value)}
                  value={viscosityIndex40.kv100 ? `${viscosityIndex40.kv100}` : ''}
                />
                <label className={css(styles.tableFieldText)}>cSt</label>
              </div>
            </div>
            {/* Actions */}
            <div className={css(styles.tableActions)}>
              <button className={css(styles.tableAction, styles.tableActionLeft)} onClick={() => calculateViscosityIndex40()}>
                <span className={css(styles.tableActionText)}>CALCULATE</span>
              </button>
              <button className={css(styles.tableAction)} onClick={() => resetCalculator('viscosityIndex40')}>
                <img className={css(styles.tableActionIcon)} src={Refresh} alt='refresh' />
                <span className={css(styles.tableActionText)}>RESET</span>
              </button>
            </div>
          </div>
          {/* Results & Errors */}
          {viscosityIndex40.error  &&
            <div className={css(styles.tableError)}>
              <p className={css(styles.tableErrorText)}>Error {viscosityIndex40.error }</p>
            </div>
          }
          <div className={css(
            styles.tableResults,
            viscosityIndex40.kv40 ? styles.tableResultsActive : {},
          )}>
            <div className={css(styles.tableResultsLabel)}>
              <p className={css(styles.tableResultsText)}>RESULTS</p>
            </div>
            <div className={css(styles.tableResultsValues)}>
              <p className={css(styles.tableResultsText)}>
                KV40 °C: {viscosityIndex40.kv40 && `${viscosityIndex40.kv40} cSt`}
              </p>
            </div> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(ViscosityIndexComponent);